import React from "react";
import * as projectService from "../services/projectService";
import { projectsTabOptions } from "../constants";
import { merge } from "lodash";

const defaultState = {
  projectListLoading: true,
  projectSelected: null,
  projectList: [],
  projectCount: [],
  projectDetails: [],
};

const ProjectStateContext = React.createContext();
const ProjectDispatchContext = React.createContext();

function projectReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        projectListLoading: action.projectListLoading,
      };
    case "SET_PROJECT_LIST":
      return {
        ...state,
        projectList: action.projectList,
      };
    case "SET_PROJECT_COUNT":
      return {
        ...state,
        projectCount: action.projectCount,
      };
    case "SET_PROJECT_SELECTED":
      return {
        ...state,
        projectSelected: action.projectSelected,
      };
    case "SET_PROJECT_DETAILS":
      return {
        ...state,
        projectDetails: action.projectDetails,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ProjectProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(projectReducer, mergedState);
  return (
    <ProjectStateContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectStateContext.Provider>
  );
}

function useProjectState() {
  const context = React.useContext(ProjectStateContext);
  if (context === undefined) {
    throw new Error("useProjectState must be used within a ProjectProvider");
  }
  return context;
}

function useProjectDispatch() {
  const context = React.useContext(ProjectDispatchContext);
  if (context === undefined) {
    throw new Error("useProjectDispatch must be used within a ProjectProvider");
  }
  return context;
}

async function getProject(dispatch, id) {
  try {
    const projectDetailsResult = await projectService.getProjectDetails(id);
    dispatch({
      type: "SET_PROJECT_DETAILS",
      projectDetails: projectDetailsResult.data.data.project,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getProjectList(dispatch, filterData, tab) {
  try {
    const projectListResult = await projectService.getProjects(filterData, tab);
    dispatch({
      type: "SET_PROJECT_LIST",
      projectList: projectListResult.data.data.projects,
    });
    dispatch({
      type: "SET_PROJECT_COUNT",
      projectCount: projectListResult.data.data.filter_counts,
    });
    dispatch({
      type: "SET_LOADING",
      projectListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function setProjectList(dispatch, data) {
  try {
    const projectListResult = data;
    dispatch({
      type: "SET_PROJECT_LIST",
      projectList: projectListResult.data.projects ?? [],
    });
    dispatch({
      type: "SET_PROJECT_COUNT",
      projectCount: projectListResult.data.filter_counts ?? 0,
    });
    dispatch({
      type: "SET_LOADING",
      projectListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getProjectListDataSet(
  projectList,
  dispatch,
  tab,
  offset,
  filterData
) {
  try {
    const result = await projectService.getProjectsTabOffset(
      tab,
      offset,
      filterData
    );
    projectList = projectList.concat(result.data.data.projects);
    dispatch({
      type: "SET_PROJECT_LIST",
      projectList: projectList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchProjects(search, filterData, tab) {
  try {
    const projectListResult = await projectService.getProjectsSearch(
      search,
      filterData,
      tab
    );
    return projectListResult.data.data;
  } catch (error) {
    console.log(error);
  }
}

export {
  ProjectProvider,
  useProjectState,
  useProjectDispatch,
  getProject,
  getProjectList,
  searchProjects,
  getProjectListDataSet,
  setProjectList,
};
