// import * as React from "react";
// import Alert from "@material-ui/lab/Alert";
// import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import BannerDownloadApp from "./DownloadBadgeApp/banner";

const styles = (theme) => ({
  containerAlert: {
    backgroundColor: "#FFECEC",
    border: "1px solid #9A1010",
    position: "relative",
    marginBottom: 8,
    "& svg": {
      color: "#9A1010",
    },
    "& div:nth-child(2)": {
      width: "100%",
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  containerAlertSpace: {
    backgroundColor: "#FFECEC",
    border: "1px solid #9A1010",
    position: "relative",
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 8,
    "& svg": {
      color: "#9A1010",
    },
    "& div:nth-child(2)": {
      width: "100%",
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& div:first-child": {
      flex: 1,
      paddingTop: 8,
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      fontSize: 11,
    },
  },
  closeAlert: {
    backgroundColor: "#ffffff",
    textTransform: "capitalize",
    marginLeft: 16,
    height: 30,
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  highLightDate: {
    fontWeight: "bold",
  },
});

const MaintenanceAlert = ({ classes, ...props }) => {
  return null;
  // return <BannerDownloadApp />;
  // const [visible, setVisible] = React.useState(true);
  // const { spacing } = props;

  // const handleCloseAlert = () => {
  //   localStorage.setItem("eos_dismiss_alert_perf", 1);
  //   setVisible(false);
  // };

  // const dismiss = localStorage.getItem("eos_dismiss_alert_perf");

  // if (dismiss || !visible) return null;

  // return (
  //   <Alert
  //     severity="warning"
  //     className={spacing ? classes.containerAlertSpace : classes.containerAlert}
  //   >
  //     <Box className={classes.content}>
  //       <Box>
  //         <Box className={classes.highLightDate} component="span" m="{1}">
  //           On Friday, Nov 10
  //         </Box>{" "}
  //         we're taking a short break from 5-9 EST (about 4 hours) for scheduled
  //         maintenance. Sorry for any hassle, and thanks for your patience.
  //       </Box>
  //       <Button
  //         className={classes.closeAlert}
  //         color="secondary"
  //         variant="outlined"
  //         size="small"
  //         onClick={handleCloseAlert}
  //       >
  //         Dismiss
  //       </Button>
  //     </Box>
  //   </Alert>
  // );
};
export default withStyles(styles)(MaintenanceAlert);
