import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 100px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: 10,
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    maxHeight: "85%",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 1200,
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  pageBodyWrapper: {
    maxHeight: "100%",
    height: "100%",
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingTop: 20,
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: 420,
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 88,
    position: "absolute",
    zIndex: 2,
    marginTop: 12,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      // left: 20,
      // top: 190,
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tabItem: {
    display: "block",
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
    borderBottom: "1px solid #d4d4d4",
  },
  tabItem: {
    marginRight: 0,
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(30),
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
      borderBottom: "2px solid #4f98bc",
    },
  },
  tabItemLabel: {
    textTransform: "capitalize",
    minWidth: 120,
    fontSize: 14,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dotIcon: {
    top: 5,
    fontSize: 20,
    color: "#9cbb65",
  },
  wrapperName: {
    paddingLeft: 36,
    position: "relative",
    minWidth: 140,
    minHeight: 32,
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 14,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
    top: 6,
  },
  avatarContainer: {
    minHeight: 32,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "120%",
      paddingTop: 16,
    },
  },
  jobNameContainer: {
    display: "flex",
    alignItems: "center",
  },
  jobIndicatorParent: {
    width: 16,
    height: 16,
    backgroundColor: "#9CBB65",
    border: "none",
    borderRadius: 4.63,
    marginRight: 16,
    flexShrink: 0,
  },
  jobIndicatorChild: {
    width: 16,
    height: 16,
    backgroundColor: "#9B9B9B",
    border: "none",
    borderRadius: 4.63,
    marginRight: 16,
    flexShrink: 0,
  },
  alertIcon: {
    color: "#F07A8C",
  },
  money: {
    textAlign: "right",
    marginRight: 20,
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default useStyles;
