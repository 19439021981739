import React from "react";
import * as workticketService from "../services/workticketService";
import { workticketsStatusOptions } from "../constants";
import { merge } from "lodash";

const defaultState = {
  workticketListLoading: true,
  workticketSelected: null,
  workticketList: [],
  workticketCount: [],
  workticketDetails: [],
  workticketLocation: null,
  scheduleMode: false,
  geoMode: false,
  completeMode: null, // Deprecated
  cancelledMode: null, // Deprecated
  notScheduleFilter: null, // Deprecated
  immediateFilter: null, // Deprecated
  unassignedFilter: null, // Deprecated
  doneFilter: null, // Deprecated
  archivedFilter: null, // Deprecated
  workticketSchedule: [],
  workticketScheduleCalendarRange: null,
};

const WorkticketStateContext = React.createContext();
const WorkticketDispatchContext = React.createContext();

function workticketReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        workticketListLoading: action.workticketListLoading,
      };
    case "SET_WORKTICKET_LIST":
      return {
        ...state,
        workticketList: action.workticketList,
      };
    case "SET_WORKTICKET_COUNT":
      return {
        ...state,
        workticketCount: action.workticketCount,
      };
    case "SET_WORKTICKET_SELECTED":
      return {
        ...state,
        workticketSelected: action.workticketSelected,
      };
    case "SET_WORKTICKET_LOCATION":
      return {
        ...state,
        workticketLocation: action.workticketLocation,
      };
    case "SET_WORKTICKET_DETAILS":
      return {
        ...state,
        workticketDetails: action.workticketDetails,
      };
    case "SET_SCHEDULE_MODE":
      return {
        ...state,
        scheduleMode: action.scheduleMode,
      };
    case "SET_SCHEDULE_LIST":
      return {
        ...state,
        workticketSchedule: action.workticketSchedule,
      };
    case "SET_SCHEDULE_CALENDAR_RANGE":
      return {
        ...state,
        workticketScheduleCalendarRange: action.ScheduleCalendarRange,
      };
    case "SET_GEO_MODE":
      return {
        ...state,
        geoMode: action.geoMode,
      };
    case "SET_COMPLETE_MODE":
      return {
        ...state,
        completeMode: action.completeMode,
      };
    case "SET_CANCELLED_MODE":
      return {
        ...state,
        cancelledMode: action.cancelledMode,
      };
    case "SET_NOT_SCHEDULED_FILTER":
      return {
        ...state,
        notScheduleFilter: action.notScheduleFilter,
        unassignedFilter: null,
      };
    case "SET_IMMEDIATE_FILTER":
      return {
        ...state,
        immediateFilter: action.immediateFilter,
      };
    case "SET_UNASSIGNED_FILTER":
      return {
        ...state,
        unassignedFilter: action.unassignedFilter,
        notScheduleFilter: null,
      };
    case "SET_DONE_FILTER":
      return {
        ...state,
        doneFilter: action.doneFilter,
      };
    case "SET_ARCHIVED_FILTER":
      return {
        ...state,
        archivedFilter: action.archivedFilter,
      };
    case "SET_RESET_LOCAL_FILTERS":
      return {
        ...state,
        cancelledMode: null,
        completeMode: null,
        notScheduleFilter: null,
        immediateFilter: null,
        unassignedFilter: null,
        doneFilter: null,
        archivedFilter: null,
      };
    case "SET_RESET_LOCAL_2_FILTERS":
      return {
        ...state,
        unassignedFilter: null,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function WorkticketProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(workticketReducer, mergedState);
  return (
    <WorkticketStateContext.Provider value={state}>
      <WorkticketDispatchContext.Provider value={dispatch}>
        {children}
      </WorkticketDispatchContext.Provider>
    </WorkticketStateContext.Provider>
  );
}

function useWorkticketState() {
  const context = React.useContext(WorkticketStateContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketState must be used within a WorkticketProvider"
    );
  }
  return context;
}

function useWorkticketDispatch() {
  const context = React.useContext(WorkticketDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketDispatch must be used within a WorkticketProvider"
    );
  }
  return context;
}

async function getWorkticket(dispatch, id) {
  try {
    const workticketDetailsResult =
      await workticketService.getWorkticketDetails(id);
    dispatch({
      type: "SET_WORKTICKET_DETAILS",
      workticketDetails: workticketDetailsResult.data.data.workticket,
    });
  } catch (error) {
    console.log(error);
  }
}

async function setWorkticketList(dispatch, data) {
  try {
    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: data?.data?.worktickets ?? [],
    });
    dispatch({
      type: "SET_WORKTICKET_COUNT",
      workticketCount: data?.data?.filter_counts ?? 0,
    });
    dispatch({
      type: "SET_LOADING",
      workticketListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketList(
  dispatch,
  filterData,
  filterAddon = undefined
) {
  try {
    const workticketListResult =
      await workticketService.getWorkticketsDeprecated(filterData, filterAddon);
    const workticketList = {
      ...workticketListResult.data.data.worktickets,
    };

    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: workticketList,
    });
    const workticketCount = {
      ...workticketListResult.data.data.filter_counts,
    };
    dispatch({
      type: "SET_WORKTICKET_COUNT",
      workticketCount: workticketCount,
    });
    dispatch({
      type: "SET_LOADING",
      workticketListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketListDataSet(
  workticketList,
  dispatch,
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  try {
    const findElement = workticketsStatusOptions.find(
      (item) => item.id === tab
    );
    const result = await workticketService.getWorkticketsTabOffset(
      findElement.tab,
      offset,
      filterData,
      filterAddon
    );
    const updateWorkticketList = [
      ...workticketList,
      ...result.data.data.worktickets,
    ];
    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: updateWorkticketList,
    });

    return true;
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketListDataSetDeprecated(
  workticketList,
  dispatch,
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  try {
    const findElement = workticketsStatusOptions.find(
      (item) => item.id === tab
    );
    const result = await workticketService.getWorkticketsTabOffsetDeprecated(
      findElement.tab,
      offset,
      filterData,
      filterAddon
    );

    workticketList[tab] = [
      ...workticketList[tab],
      ...result.data.data.worktickets,
    ];

    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: workticketList,
    });

    return true;
  } catch (error) {
    console.log(error);
  }
}

async function searchWorktickets(search, filterData) {
  try {
    const workticketListResult = await workticketService.getWorkticketsSearch(
      search,
      filterData
    );
    return workticketListResult.data.data;
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketListProject(project_id, dispatch, filterData) {
  try {
    const workticketListResult = await workticketService.getWorkticketsProject(
      project_id,
      filterData
    );
    const workticketList = {
      ...workticketListResult.data.data.worktickets,
    };

    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: workticketList,
    });
    const workticketCount = {
      ...workticketListResult.data.data.filter_counts,
    };
    dispatch({
      type: "SET_WORKTICKET_COUNT",
      workticketCount: workticketCount,
    });
    dispatch({
      type: "SET_LOADING",
      workticketListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorkticketListDataSetProject(
  project_id,
  workticketList,
  dispatch,
  tab,
  offset,
  filterData
) {
  try {
    const findElement = workticketsStatusOptions.find(
      (item) => item.id === tab
    );
    const result = await workticketService.getWorkticketsTabOffsetProject(
      project_id,
      findElement.tab,
      offset,
      filterData
    );
    workticketList[tab] = workticketList[tab].concat(
      result.data.data.worktickets
    );
    dispatch({
      type: "SET_WORKTICKET_LIST",
      workticketList: workticketList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchWorkticketsProject(project_id, search, filterData) {
  try {
    const workticketListResult =
      await workticketService.getWorkticketsSearchProject(
        project_id,
        search,
        filterData
      );
    return workticketListResult.data.data.worktickets;
  } catch (error) {
    console.log(error);
  }
}

async function geoWorktickets(geoLocation) {
  try {
    const geoData = {
      latitude: geoLocation.coords.latitude,
      longitude: geoLocation.coords.longitude,
      radius: 500,
    };
    const workticketListResult = await workticketService.getWorkticketsGeo(
      geoData
    );
    return workticketListResult.data.data.worktickets;
  } catch (error) {
    console.log(error);
  }
}

export {
  WorkticketProvider,
  useWorkticketState,
  useWorkticketDispatch,
  getWorkticket,
  setWorkticketList,
  getWorkticketList,
  getWorkticketListDataSet,
  searchWorktickets,
  geoWorktickets,
  getWorkticketListProject,
  getWorkticketListDataSetProject,
  searchWorkticketsProject,
  getWorkticketListDataSetDeprecated,
};
