import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DeleteIcon from "assets/icons/deleteIcon";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import FormInput from "components/ui/FormContent/formInput";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import { getInvoiceSC, submitInvoiceSC } from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const invoiceTypes = [
  {
    value: "Labor",
    label: "Labor",
  },
  {
    value: "Travel",
    label: "Travel",
  },
  {
    value: "Material",
    label: "Material",
  },
  {
    value: "Freight",
    label: "Freight",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const laborSkillLevel = [
  {
    value: "1",
    label: "Supevisor",
  },
  {
    value: "2",
    label: "Technician",
  },
  {
    value: "3",
    label: "Helper",
  },
];

const laborHourType = [
  {
    value: "1",
    label: "Regular",
  },
  {
    value: "2",
    label: "Overtime",
  },
  {
    value: "3",
    label: "Double Time/Holiday",
  },
];

const otherDescription = [
  {
    value: "Discount",
    label: "Discount",
  },
  {
    value: "Management Fee",
    label: "Management Fee",
  },
  {
    value: "Markup",
    label: "Markup",
  },
  {
    value: "Overhead & Profit",
    label: "Overhead & Profit",
  },
  {
    value: "Rental Fee",
    label: "Rental Fee",
  },
  {
    value: "Shipping & Handling",
    label: "Shipping & Handling",
  },
  {
    value: "Subcontractor Cost",
    label: "Subcontractor Cost",
  },
];

const initialRow = {
  chargeType: null,
  description: "",
  laborSkillLevel: null,
  laborHourType: null,
  laborNumberTechs: 0,
  laborHourRate: 0,
  laborHours: 0,
  otherComment: "",
  otherDescription: null,
  total: 0,
  error: [],
};

const WorkticketInvoiceWGDialog = (props) => {
  const classes = useStyles();
  const { workticket, handleClose, open } = props;
  const wtContext = useWorkticketView();
  const dispatchContext = wtContext[1];
  const [typeWork, setTypeWork] = useState(null);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [rows, setRows] = useState([{ ...initialRow }]);
  const [isFistLoad, setIsFistLoad] = useState(true);
  const [description, setDescription] = useState("");
  const [totalDollars, setTotalDollars] = useState(0);
  const [ust, setUst] = useState(0);
  const [submitDate, setSubmitDate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(moment().format("MM/DD/YYYY"));
  const [invoiceNumber, setInvoiceNumber] = useState(workticket.number);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (workticket) {
      setTypeWork(workticket?.external?.type);
    }
  }, [workticket]);

  useEffect(() => {
    const loadInvoice = async () => {
      setIsLoadingProcess(true);
      const responseInvoice = await getInvoiceSC(workticket.id);
      const { data, exists } = responseInvoice.data;
      if (exists) {
        if (!workticket?.external?.finance?.length) {
          workticket.external.finance = [
            { total: data.InvoiceTotal, description: "Invoice" },
          ];
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: workticket,
          });
        }

        setIsFistLoad(false);
        setDescription(data.Description);
        setInvoiceNumber(data.InvoiceNumber);
        setUst(data.InvoiceTax);
        setTotalDollars(data.InvoiceTotal - data.InvoiceTax);
        setInvoiceDate(data.InvoiceDateStr);
        setSubmitDate(data.InvoiceDateStr);
        if (data.InvoiceAmountsDetails) {
          const rowsLoaded = [];
          if (data.InvoiceAmountsDetails.LaborAmount) {
            const dataLabor = {
              chargeType: "Labor",
              total: data.InvoiceAmountsDetails.LaborAmount,
              error: [],
            };

            if (workticket.external.type === 2) {
              dataLabor.laborSkillLevel = data.Labors[0].SkillLevel;
              dataLabor.laborHourType = data.Labors[0].LaborType;
              dataLabor.laborNumberTechs = data.Labors[0].NumOfTech;
              dataLabor.laborHourRate = data.Labors[0].HourlyRate;
              dataLabor.laborHours = data.Labors[0].Hours;
            }

            rowsLoaded.push(dataLabor);
          }
          if (data.InvoiceAmountsDetails.TravelAmount) {
            rowsLoaded.push({
              chargeType: "Travel",
              total: data.InvoiceAmountsDetails.TravelAmount,
              error: [],
            });
          }
          if (data.InvoiceAmountsDetails.MaterialAmount) {
            rowsLoaded.push({
              chargeType: "Material",
              total: data.InvoiceAmountsDetails.MaterialAmount,
              error: [],
            });
          }
          if (data.InvoiceAmountsDetails.FreightAmount) {
            rowsLoaded.push({
              chargeType: "Freight",
              total: data.InvoiceAmountsDetails.FreightAmount,
              error: [],
            });
          }
          if (data.InvoiceAmountsDetails.OtherAmount) {
            const dataOther = {
              chargeType: "Other",
              total: data.InvoiceAmountsDetails.OtherAmount,
              error: [],
            };

            if (workticket.external.type === 2) {
              dataOther.otherDescription = data.Others[0].Type;
              dataOther.otherComment = data.Others[0].Description;
            }
            rowsLoaded.push(dataOther);
          }
          setRows(rowsLoaded);
        }
      } else {
        setIsFistLoad(true);
      }
      setIsLoadingProcess(false);
    };

    loadInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workticket]);

  useEffect(() => {
    if (!open) {
      setRows([{ ...initialRow }]);
      setTotalDollars(0);
      setUst(0);
      setDescription("");
      setIsFistLoad(true);
    }
  }, [open]);

  const handleChangeChargeType = (n, value) => {
    const newItem = { ...initialRow, chargeType: value?.value };
    rows[n] = newItem;
    setRows([...rows]);
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
  };

  const handleChangeSkillLevel = (n, value) => {
    rows[n].laborSkillLevel = value?.value;
    setRows([...rows]);
  };

  const handleChangeOtherDescription = (n, value) => {
    rows[n].otherDescription = value?.value;
    setRows([...rows]);
  };

  const handleChangeHourType = (n, value) => {
    rows[n].laborHourType = value?.value;
    setRows([...rows]);
  };

  const handleSendInvoice = async (submitInvoice) => {
    let isGood = true;
    for (let i = 0; i < rows.length; i++) {
      const error = [];
      if (!rows[i].chargeType) {
        error.push({
          key: "chargeType",
          message: "Required",
        });
        isGood = false;
      }

      if (!rows[i].total) {
        error.push({
          key: "total",
          message: "Required",
        });
        isGood = false;
      }

      if (rows[i].chargeType === "Labor" && typeWork === 2) {
        if (!rows[i].laborSkillLevel) {
          error.push({
            key: "laborSkillLevel",
            message: "Required",
          });
          isGood = false;
        }
        if (!rows[i].laborHourType) {
          error.push({
            key: "laborHourType",
            message: "Required",
          });
          isGood = false;
        }
        if (!rows[i].laborNumberTechs) {
          error.push({
            key: "laborNumberTechs",
            message: "Required",
          });
          isGood = false;
        }
        if (!rows[i].laborHourRate) {
          error.push({
            key: "laborHourRate",
            message: "Required",
          });
          isGood = false;
        }
        if (!rows[i].laborHours) {
          error.push({
            key: "laborHours",
            message: "Required",
          });
          isGood = false;
        }
      }

      if (rows[i].chargeType === "Other" && !rows[i].otherDescription) {
        error.push({
          key: "otherDescription",
          message: "Required",
        });
        isGood = false;
      }

      rows[i].error = error;
    }
    if (!isGood) {
      setRows([...rows]);
      return;
    }
    try {
      setIsLoadingProcess(true);
      const totalAmount = parseFloat(totalDollars) + parseFloat(ust);
      const totalLabor = rows
        .filter((item) => item.chargeType === "Labor")
        .reduce((partialSum, a) => partialSum + parseFloat(a.total), 0);
      const totalTravel = rows
        .filter((item) => item.chargeType === "Travel")
        .reduce((partialSum, a) => partialSum + parseFloat(a.total), 0);
      const totalMaterial = rows
        .filter((item) => item.chargeType === "Material")
        .reduce((partialSum, a) => partialSum + parseFloat(a.total), 0);
      const totalFreight = rows
        .filter((item) => item.chargeType === "Freight")
        .reduce((partialSum, a) => partialSum + parseFloat(a.total), 0);
      const totalOther = rows
        .filter((item) => item.chargeType === "Other")
        .reduce((partialSum, a) => partialSum + parseFloat(a.total), 0);

      const laborLines = rows
        .filter((item) => item.chargeType === "Labor")
        .map((item) => {
          return {
            SkillLevel: item.laborSkillLevel,
            LaborType: item.laborHourType,
            NumOfTech: item.laborNumberTechs,
            HourlyRate: item.laborHourRate,
            Hours: item.laborHours,
            Amount: item.total,
          };
        });

      const otherLines = rows
        .filter((item) => item.chargeType === "Other")
        .map((item) => {
          return {
            Type: item.otherDescription,
            Description: item.otherComment,
            Amount: item.total,
          };
        });

      const invoiceData = {
        TypeWork: typeWork,
        Tax: parseFloat(parseFloat(ust).toFixed(2)),
        Total: parseFloat(totalAmount).toFixed(2),
        Description: description,
        LaborAmount: parseFloat(totalLabor).toFixed(2),
        TravelAmount: parseFloat(totalTravel).toFixed(2),
        MaterialAmount: parseFloat(totalMaterial).toFixed(2),
        FreightAmount: parseFloat(totalFreight).toFixed(2),
        OtherAmount: parseFloat(totalOther).toFixed(2),
        LaborLines: laborLines,
        OtherLines: otherLines,
      };

      await submitInvoiceSC(workticket.id, {
        invoiceData: invoiceData,
      });
      workticket.external.finance = [
        { total: parseFloat(totalAmount).toFixed(2), description: "Invoice" },
      ];
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
      setIsLoadingProcess(false);
      handleClose();
      setSuccessMessage("Invoice has been submitted");
      setOpenSuccess(true);
    } catch (e) {
      logException(e, "Cannot submit invoice");
    }
  };

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
  };

  const handleBlur = async (event) => {
    if (event.name === "general_description") {
      setDescription(event.value);
    } else if (event.name === "ust" && !isNaN(event.value)) {
      setUst(event.value);
    }
  };

  const handleRowBlur = (n, event) => {
    if (["total"].includes(event.name) && !isNaN(event.value)) {
      rows[n].total = event.value;
      rows[n].error = [];
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    } else if (
      ["laborNumberTechs", "laborHourRate", "laborHours"].includes(
        event.name
      ) &&
      !isNaN(event.value)
    ) {
      rows[n][event.name] = event.value;
      rows[n].error = [];
      const totalRow =
        rows[n].laborNumberTechs * rows[n].laborHourRate * rows[n].laborHours;
      rows[n].total = totalRow;
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    } else if (["otherComment"].includes(event.name)) {
      rows[n][event.name] = event.value;
      rows[n].error = [];
    } else {
      rows[n][event.name] = 0;
      rows[n].error = [
        {
          key: event.name,
          message: "Required",
        },
      ];
      rows[n].total = 0;
      const sumDollars = rows.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total),
        0
      );
      setTotalDollars(sumDollars);
    }
    setRows([...rows]);
  };

  const handleAddClick = () => {
    const newRow = { ...initialRow };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (n) => {
    rows.splice(n, 1);
    const sumDollars = rows.reduce(
      (partialSum, a) => partialSum + parseFloat(a.total),
      0
    );
    setTotalDollars(sumDollars);
    setRows([...rows]);
  };

  const typesSelected = rows.map((row) => row.chargeType);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {isLoadingProcess ? (
          <Box className={classes.loadingContainer}>
            <LoadingIndicator />
          </Box>
        ) : (
          <>
            <DialogContent>
              <IconButton
                aria-label="close"
                onClick={props.handleClose}
                className={classes.wrapperClose}
              >
                <CloseIcon className={classes.iconClose} />
              </IconButton>
              {!isLoadingProcess && (
                <>
                  <Grid container spacing={2} className={classes.dialogHeader}>
                    <Grid item sm={12}>
                      <Box className={classes.formHeaderContainer}>
                        <Box className={classes.formIconDialog}>
                          <TicketIcon className={classes.iconDialog} />
                        </Box>
                        <Typography
                          variant="h4"
                          className={classes.formTitleDialogInvoice}
                          gutterBottom
                        >
                          {workticket.number}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.formContainerDialogMultiple}>
                    <Grid container spacing={0}>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            WO Number:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {workticket.number}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Invoice Number:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {invoiceNumber}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Invoice Date:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {invoiceDate}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            WO Status:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            <WorkticketSoloStatusChip
                              status={workticket.status}
                              type={workticket.type}
                              category={workticket.workticket_category}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Invoice Status:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {submitDate ? "Submitted" : "Pending"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            Submitted Date:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {submitDate ?? "N/A"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.headerFieldInvoiceContainer}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice}
                            gutterBottom
                          >
                            NTE:
                          </Typography>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderValueInvoice2}
                            gutterBottom
                          >
                            {`$${parseFloat(workticket.external.nte).toFixed(
                              2
                            )} USD`}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12}>
                        <Box className={classes.descriptionFieldContainer}>
                          <FormInput
                            name="general_description"
                            gridSizes={[{ size: "md", val: 12 }]}
                            label="Description of work performed*"
                            value={description}
                            sameLine={true}
                            handleBlur={handleBlur}
                            rows={3}
                            multiline
                            noMargin
                            readonly={!isFistLoad}
                          />
                        </Box>
                      </Grid>
                      {rows.map((row, n) => (
                        <React.Fragment key={n}>
                          <Grid item md={5} sm={12} />
                          <Grid item md={2} sm={5}>
                            <Box className={classes.invoiceFieldWrapper}>
                              <FormSelectAuto
                                gridSizes={[{ size: "md", val: 12 }]}
                                options={invoiceTypes.filter(
                                  (item) => !typesSelected.includes(item.value)
                                )}
                                name="chargeType"
                                label="Charge Type"
                                handleChange={(event, value) =>
                                  handleChangeChargeType(n, value)
                                }
                                value={invoiceTypes.find(
                                  (item) => item.value === row.chargeType
                                )}
                                error={row.error}
                                readonly={!isFistLoad}
                              />
                            </Box>
                          </Grid>
                          {typeWork === 1 && (
                            <Grid item md={4} sm={5}>
                              <FormInput
                                name="total"
                                gridSizes={[{ size: "md", val: 12 }]}
                                label="Total"
                                value={row.total}
                                sameLine={true}
                                handleBlur={(event) => handleRowBlur(n, event)}
                                error={row.error}
                                readonly={!isFistLoad}
                              />
                            </Grid>
                          )}
                          {typeWork === 2 && row.chargeType !== "Labor" && (
                            <Grid item md={4} sm={5}>
                              <FormInput
                                name="total"
                                gridSizes={[{ size: "md", val: 12 }]}
                                label="Total"
                                value={row.total}
                                sameLine={true}
                                handleBlur={(event) => handleRowBlur(n, event)}
                                error={row.error}
                                readonly={!isFistLoad}
                              />
                            </Grid>
                          )}
                          {typeWork === 2 && row.chargeType === "Labor" && (
                            <>
                              <Grid item md={2} sm={5}>
                                <Box className={classes.invoiceFieldWrapper}>
                                  <FormSelectAuto
                                    gridSizes={[{ size: "md", val: 12 }]}
                                    options={laborSkillLevel}
                                    name="laborSkillLevel"
                                    label="Sill Level"
                                    handleChange={(event, value) =>
                                      handleChangeSkillLevel(n, value)
                                    }
                                    value={laborSkillLevel.find(
                                      (item) =>
                                        parseInt(item.value) ===
                                        parseInt(row.laborSkillLevel)
                                    )}
                                    error={row.error}
                                    readonly={!isFistLoad}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={2} sm={5}>
                                <Box className={classes.invoiceFieldWrapper}>
                                  <FormSelectAuto
                                    gridSizes={[{ size: "md", val: 12 }]}
                                    options={laborHourType}
                                    name="laborHourType"
                                    label="Hour Type"
                                    handleChange={(event, value) =>
                                      handleChangeHourType(n, value)
                                    }
                                    value={laborHourType.find(
                                      (item) =>
                                        parseInt(item.value) ===
                                        parseInt(row.laborHourType)
                                    )}
                                    error={row.error}
                                    readonly={!isFistLoad}
                                  />
                                </Box>
                              </Grid>
                            </>
                          )}
                          <Grid item md={1} sm={2}>
                            {isFistLoad ? (
                              <Box className={classes.iconContainer}>
                                <IconButton
                                  aria-label="delete"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={() => handleDeleteRow(n)}
                                  disabled={rows.length === 1}
                                  className={classes.iconButtonAction}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            ) : null}
                          </Grid>
                          {typeWork === 2 && row.chargeType === "Labor" && (
                            <>
                              <Grid item md={5} sm={12} />
                              <Grid item md={1} sm={5}>
                                <FormInput
                                  name="laborNumberTechs"
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  label="# Techs"
                                  value={row.laborNumberTechs}
                                  sameLine={true}
                                  handleBlur={(event) =>
                                    handleRowBlur(n, event)
                                  }
                                  error={row.error}
                                  readonly={!isFistLoad}
                                />
                              </Grid>
                              <Grid item md={2} sm={5}>
                                <FormInput
                                  name="laborHourRate"
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  label="Hourly Rate"
                                  value={row.laborHourRate}
                                  sameLine={true}
                                  handleBlur={(event) =>
                                    handleRowBlur(n, event)
                                  }
                                  error={row.error}
                                  readonly={!isFistLoad}
                                />
                              </Grid>
                              <Grid item md={1} sm={5}>
                                <FormInput
                                  name="laborHours"
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  label="Hours"
                                  value={row.laborHours}
                                  sameLine={true}
                                  handleBlur={(event) =>
                                    handleRowBlur(n, event)
                                  }
                                  error={row.error}
                                  readonly={!isFistLoad}
                                />
                              </Grid>
                              <Grid item md={2} sm={5}>
                                <FormInput
                                  name="total"
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  label="Total"
                                  value={row.total}
                                  sameLine={true}
                                  handleBlur={(event) =>
                                    handleRowBlur(n, event)
                                  }
                                  error={row.error}
                                  readonly={true}
                                />
                              </Grid>
                            </>
                          )}
                          {typeWork === 2 && row.chargeType === "Other" && (
                            <>
                              <Grid item md={5} sm={12} />
                              <Grid item md={3} sm={12}>
                                <Box className={classes.invoiceFieldWrapper}>
                                  <FormSelectAuto
                                    gridSizes={[{ size: "md", val: 12 }]}
                                    options={otherDescription}
                                    name="otherDescription"
                                    label="Description"
                                    handleChange={(event, value) =>
                                      handleChangeOtherDescription(n, value)
                                    }
                                    value={otherDescription.find(
                                      (item) =>
                                        item.value === row.otherDescription
                                    )}
                                    error={row.error}
                                    readonly={!isFistLoad}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={3} sm={12}>
                                <FormInput
                                  name="otherComment"
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  label="Comment"
                                  value={row.otherComment}
                                  sameLine={true}
                                  handleBlur={(event) =>
                                    handleRowBlur(n, event)
                                  }
                                  error={row.error}
                                  readonly={!isFistLoad}
                                />
                              </Grid>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                      {isFistLoad &&
                        invoiceTypes.filter(
                          (item) => !typesSelected.includes(item.value)
                        ).length >= 1 && (
                          <>
                            <Grid item md={5} sm={12} />
                            <Grid item md={7} sm={12}>
                              <Box
                                className={classes.addContainerInvoice}
                                onClick={handleAddClick}
                              >
                                <AddIcon
                                  className={classes.iconAddLineInvoice}
                                />{" "}
                                Add Item
                              </Box>
                            </Grid>
                          </>
                        )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={5} sm={6}></Grid>
                      <Grid item md={2} sm={6}>
                        <Box className={classes.fieldComposedInvoice}>
                          <Typography
                            variant="h4"
                            className={classes.labelHeaderInvoice2}
                            gutterBottom
                          >
                            Tax
                          </Typography>
                          <FormInput
                            gridSizes={[{ size: "md", val: 12 }]}
                            name="ust"
                            withoutLabel
                            value={ust}
                            handleBlur={handleBlur}
                            noMargin
                            readonly={!isFistLoad}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={4} sm={6}>
                        <Box className={classes.resumeInvoiceFieldsContainer}>
                          <Box className={classes.headerFieldInvoiceContainer}>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoice}
                              gutterBottom
                            >
                              Subtotal:
                            </Typography>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderValueInvoice2}
                              gutterBottom
                            >
                              {parseFloat(totalDollars).toFixed(2)}
                            </Typography>
                          </Box>
                          <Box className={classes.headerFieldInvoiceContainer}>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoiceResume2}
                            >
                              Total Cost:
                            </Typography>
                            <Typography
                              variant="h4"
                              className={classes.labelHeaderInvoiceResume}
                            >
                              {parseFloat(
                                parseFloat(
                                  parseFloat(totalDollars).toFixed(2)
                                ) + parseFloat(parseFloat(ust).toFixed(2))
                              ).toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
              {!isFistLoad ? (
                <Alert severity="info" className={classes.infoAlertDialog}>
                  Invoice has already been submitted. Once submitted, you can
                  only edit or resubmit it if it gets rejected.
                </Alert>
              ) : null}
            </DialogContent>
            {isFistLoad ? (
              <DialogActions className={classes.actionsDialogWrapperInvoice}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classNames(classes.button, classes.buttonOutlined)}
                  onClick={props.handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  className={classNames(classes.button, classes.buttonPrimary)}
                  onClick={() => handleSendInvoice(true)}
                  disabled={
                    !rows.length ||
                    parseFloat(parseFloat(totalDollars).toFixed(2)) +
                      parseFloat(parseFloat(ust).toFixed(2)) >
                      parseFloat(workticket.external.nte)
                  }
                >
                  Submit Invoice
                </Button>
              </DialogActions>
            ) : (
              <DialogActions className={classes.actionsDialogWrapperInvoice}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classNames(classes.button, classes.buttonOutlined)}
                  onClick={props.handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={successMessage}
      />
    </>
  );
};

export default WorkticketInvoiceWGDialog;
