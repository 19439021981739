import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import FilesDisplayUploaded from "components/ui/FilesDisplayUploaded/filesDisplayUploaded";
import { saveWorkticketFileWG } from "services/workticketService";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketFilePaperWG = () => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [files, setFiles] = useState([]);
  const [filesDisplay, setFilesDisplay] = useState([]);
  const [update, setUpdate] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [stateContext] = useWorkticketView();
  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!files.length) {
      setUpdate(0);
    }
  }, [files]);

  useEffect(() => {
    if (!isLoading) {
      setFilesDisplay(
        workticket.files.filter(
          (file) => file.pivot && parseInt(file.pivot.is_paper_ticket) === 1
        )
      );
    }
  }, [workticket, isLoading]);

  const handleRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
  };

  const handleUploadFiles = async () => {
    try {
      setIsUploading(true);
      const data = {
        is_signature: 0,
        is_paper_ticket: 1,
        files: files,
      };
      const fileResult = await saveWorkticketFileWG(workticket.id, data);
      setFilesDisplay([...filesDisplay, ...fileResult.data.data.files]);
      setFiles([]);
      setIsUploading(false);
    } catch (e) {
      logException(e, "Cannot save files");
    }
  };

  const handleRemoveDisplay = (id) => {
    const filesDisplayRemove = filesDisplay.filter((file) => file.id !== id);
    setFilesDisplay(filesDisplayRemove);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={200} />;
  }

  if (!hasPermission(permissionWorkticket.UPLOAD_FILES, permissions)) {
    return "";
  }

  return (
    <Box className={classes.containerFile}>
      {!Boolean(files.length) && (
        <Typography
          variant="h4"
          className={classes.titleSectionBody}
          gutterBottom
        >
          Paper Tickets
        </Typography>
      )}

      <FileUpload
        title={"Paper Tickets"}
        value={files}
        handleChange={setFiles}
        handleUpdate={setUpdate}
        filesDisplay={
          Boolean(update) && (
            <FilesDisplay files={files} handleRemove={handleRemove} />
          )
        }
      />
      {Boolean(update) && (
        <>
          <Box className={classes.containerUploadFile}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className={classNames(classes.button, classes.buttonUpload)}
              onClick={handleUploadFiles}
              disabled={isUploading}
            >
              Finish Upload
            </Button>
          </Box>
          {isUploading && <LinearProgress color="secondary" />}
        </>
      )}
      <Box className={classes.containerDisplayFiles}>
        <FilesDisplayUploaded
          files={filesDisplay}
          handleRemoveDisplay={handleRemoveDisplay}
        />
      </Box>
    </Box>
  );
};

export default WorkticketFilePaperWG;
