import http from "./httpService";
import { apiUrl } from "lib/config";
const apiEndpoint = apiUrl + "/files";

export function getFileView(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function deleteFile(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}
