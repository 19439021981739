import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import * as classNames from "classnames";
import React from "react";
import useStyles from "./styles";
import moment from "moment";

export default function AutoGrid({ setModal, selectedModalViewResponse }) {
  const classes = useStyles();

  let response = selectedModalViewResponse?.data?.form_accident
    ? selectedModalViewResponse?.data?.form_accident
    : "";

  let data = response?.data ? JSON.parse(response?.data) : [];

  let witnesses = data?.witnesses ? JSON.parse(data.witnesses) : [];

  return (
    <Dialog open={true} fullWidth={true} maxWidth={"md"}>
      <IconButton
        aria-label="close"
        onClick={() => {
          setModal(0);
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                className={classes.Box}
                style={{
                  overflow: "auto !important",
                }}
              >
                <Typography className={classes.modalTitle}>
                  Accident / Incident Investigation Report
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Person conducting investigation
                    </Typography>
                  </Grid>
                  {/* </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      First Name
                    </Typography>
                    <TextField
                      name="name"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Last Name
                    </Typography>
                    <TextField
                      name="email"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.created_by?.last_name}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Site Name
                    </Typography>
                    <TextField
                      name="person_conducting_investigation_site_num"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={response?.job?.job_description}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Workers compensation carrier
                    </Typography>
                    <TextField
                      name="person_conducting_investigation_workers_comp_carrier"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.worker_compensation_carrier}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Employee of Accident / Incident
                    </Typography>
                    <TextField
                      name="employee_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={
                        response?.user?.first_name +
                        " " +
                        response?.user?.last_name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5}>
                    <Typography className={classes.subSubTitle}>
                      Date of Birth
                    </Typography>
                    <TextField
                      name="dob"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.dob).format("MM/DD/YYYY")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className={classes.subSubTitle}>Sex</Typography>
                    <RadioGroup
                      aria-label="sex"
                      name="sex"
                      value={data?.gender ? data.gender : ""}
                    >
                      <FormControlLabel
                        name="sex"
                        value="male"
                        control={<Radio color="primary" />}
                        label="M"
                      />
                      <FormControlLabel
                        name="sex"
                        value="female"
                        control={<Radio color="primary" />}
                        label="F"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography className={classes.subSubTitle}>
                      Hire Date
                    </Typography>
                    <TextField
                      name="hire_date"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.hire_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}></Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Home Phone # (Area code)
                    </Typography>
                    <TextField
                      name="home_phone"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      Cell Phone # (Area code)
                    </Typography>
                    <TextField
                      name="cell_phone"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.cell_phone}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Home Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subSubTitle}>
                      Street
                    </Typography>
                    <TextField
                      name="street"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_street}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Apt. #
                    </Typography>
                    <TextField
                      name="apt"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_apt}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      City
                    </Typography>
                    <TextField
                      name="city"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_city}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      State
                    </Typography>
                    <TextField
                      name="state"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_state}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className={classes.subSubTitle}>Zip</Typography>
                    <TextField
                      name="zip"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.home_zip}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      Description of Accident/Incident (Be specific)
                    </Typography>
                    <TextField
                      name="description_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_description}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subSubTitle}>
                      Date of Accident/Incident
                    </Typography>
                    <TextField
                      name="date_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.accident_date).format("MM/DD/YYYY")}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subSubTitle}>
                      Time of Accident/Incident
                    </Typography>
                    <TextField
                      name="type_of_accident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_time}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className={classes.subSubTitle}>
                      Day first reported
                    </Typography>
                    <TextField
                      name="day_first_reported"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.accident_date_first_report).format(
                        "MM/DD/YYYY"
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      Part of the body Affected
                    </Typography>
                    <TextField
                      name="part_of_the_body_affected"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_part_affected}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Was the person performing his/her regular duties?
                    </Typography>

                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="part_of_regular_duty"
                        name="part_of_regular_duty"
                        value={data?.regular_duties ? data.regular_duties : ""}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="part_of_regular_duty"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="No"
                          name="part_of_regular_duty"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Typography>
                    Place a (x) mark on the body frame bellow indicating what
                    particular area(s) on your body that is injured. Specify
                    whether it is in the front or back as well. Make sure it
                    matches the description you have written down describing the
                    accident.
                  </Typography>
                </Grid>
                <Box
                  style={{
                    justifyContent: "center",
                    marginTop: "50px",
                    display: "flex",
                  }}
                >
                  <img src={data?.accident_image} />
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      {" "}
                      Location of Accident/Incident
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography className={classes.subSubTitle}>
                      Street
                    </Typography>
                    <TextField
                      name="accident_street"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_street}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Apt. #
                    </Typography>
                    <TextField
                      name="accident_apt"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_apt}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.subSubTitle}>
                      City
                    </Typography>
                    <TextField
                      name="accident_city"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_city}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      State
                    </Typography>
                    <TextField
                      name="accident_state"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_state}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography className={classes.subSubTitle}>Zip</Typography>
                    <TextField
                      name="accident_zip"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_zip}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Please list any witnesses
                    </Typography>
                    {witnesses?.map((v) => {
                      return (
                        <span>
                          <Typography className={classes.subSubTitle}>
                            Name
                          </Typography>
                          <TextField
                            name="witness_name"
                            className={classes.field}
                            InputProps={{
                              className: classes.input,
                              disableUnderline: true,
                            }}
                            fullWidth
                            value={v}
                          />
                        </span>
                      );
                    })}
                  </Grid>
                  <Grid container spacing={3}></Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      Cause of incident (Be specific)
                    </Typography>
                    <TextField
                      name="cause_of_incident"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.accident_cause}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subTitle}>
                      Was medical treatment requested?
                    </Typography>

                    <Grid container spacing={1}>
                      <RadioGroup
                        aria-label="part_of_regular_duty"
                        name="part_of_regular_duty"
                        value={
                          data?.medical_treatment ? data.medical_treatment : ""
                        }
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Yes"
                          name="med_treatment_requested"
                        />
                        <FormControlLabel
                          value="0"
                          name="med_treatment_requested"
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      If "YES" WHERE (Name of hospital or clinic)
                    </Typography>
                    <TextField
                      name="name_of_hospital"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.name_hospital_clinic}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      Address of hospital or clinic
                    </Typography>
                    <TextField
                      name="address_of_hospital"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.address_hospital_clinic}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.subSubTitle}>
                      What actions have been taken to prevent the recurrence of
                      the incident?
                    </Typography>
                    <TextField
                      name="actions_taken"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={data?.taken_action_prevent}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.subSubTitle}>
                      Date of corrective action
                    </Typography>

                    <TextField
                      name="email"
                      className={classes.field}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      fullWidth
                      value={moment(data?.corrective_action_date).format(
                        "MM/DD/YYYY"
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  <Typography className={classes.subTitle}>
                    Signature
                  </Typography>
                </Grid>
                <br />
                <br />
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  <img src={response?.signature_image} />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions className={classes.centerAlignBtn}>
        <Box>
          <Grid container className={classes.centerAlignBtn}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonSecondary)}
              onClick={() => setModal(0)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
