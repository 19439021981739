import http from "./httpService";
import { apiSurfaceUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiSurfaceUrl}/worktickets`;
const apiEndpointSingular = apiSurfaceUrl + "/workticket";

export function getWorktickets(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}`, {});
}

export function createWorkticketEmergency(data) {
  return http.post(`${apiEndpointSingular}`, setFormData(data));
}

export function getWorkticketsTabOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}`
  );
}

export function getWorkticketsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`);
}

export function getWorkticketDetails(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function getWorkticketScheduleCalendar(
  start_date = undefined,
  end_date = undefined
) {
  return http.get(
    `${apiEndpoint}/schedules?${start_date ? `start_date=${start_date}` : ``}${
      end_date ? `&end_date=${end_date}` : ``
    }`
  );
}

export function saveWorkticketFollow(data) {
  return http.post(`${apiEndpoint}/follow`, data);
}

export function getWorkticketPdf(
  id,
  timekeeping = undefined,
  signature = undefined,
  image = undefined
) {
  const tParam = timekeeping ? "has_timekeeping=1&" : "";
  const sParam = signature ? "has_signature=1&" : "";
  const iParam = image ? "has_image=1&" : "";
  return http.get(`${apiEndpoint}/${id}/pdf?${tParam}${sParam}${iParam}`, {
    responseType: "blob",
  });
}

export function workticketExport(data) {
  return http.post(`${apiEndpoint}/export-ids`, data);
}

export function rate(id_workticket, data) {
  return http.put(`${apiEndpoint}/${id_workticket}/rate`, data);
}
