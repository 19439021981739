import React from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import LocationIcon from "@material-ui/icons/MyLocation";
import useStyles from "./styles";

const WorkticketOnSiteChip = (props) => {
  const classes = useStyles();
  const { on_site, status } = props;

  if (![2, 4, 5].includes(status)) {
    return null;
  }

  let classChip,
    iconChip,
    labelChip = "";

  if (on_site === 1) {
    classChip = classes.chipOnSite;
    iconChip = <LocationIcon />;
    labelChip = "Verified On-Site";
  } else {
    classChip = classes.chipNotOnSite;
    iconChip = <LocationIcon />;
    labelChip = "Not Verified On-Site";
  }

  return (
    <Chip
      icon={iconChip}
      label={labelChip}
      className={classNames(classes.chip, classChip)}
    />
  );
};

export default WorkticketOnSiteChip;
