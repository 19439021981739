import React, { useState, useEffect, useContext, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { getDistance } from "geolib";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelect from "components/ui/FormContent/formSelect";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import ErrorMessageDialog from "pages/quotePage/dialog/errorMessageDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TicketIcon from "@material-ui/icons/Receipt";
import LocationIcon from "@material-ui/icons/MyLocation";
import LocationOff from "@material-ui/icons/LocationOff";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import ErrorDialog from "components/ui/dialog/errorDialog";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import { convertDateFormatField } from "components/util/timeFormat";
import WorkticketBillingPartner from "./workticketBillingPartner";
import IconLink from "@material-ui/icons/ArrowForward";
import { toast } from "react-toastify";
import {
  acceptWorkticketSC,
  updateWorkticketDetails,
  updateWorkticketWGStatus,
  updateWorkticketWGApprove,
  assignUserWorkticket,
  removeUserWorkticket,
  clockInWorkticketNonStrict,
  clockOutWorkticket,
  getWorkticketDetails,
  changePartner,
  reviewWorkticketCancellation,
} from "services/workticketService";
import { getUserSchedulesAll, getUserJobSchedules } from "services/userService";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import { DaysFromNowStandard } from "components/util/timeFormat";
import useStyles from "./styles";

import { permissionWorkticket, hasPermission } from "lib/permissions";
import {
  workticketStatusWG,
  workticketStatusWGVerify,
  workticketClockOutWG,
  workticketsCancellationReasonOptions,
} from "constants.js";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const cancelledStatus = ["COMPLETED-NO CHARGE", "CANCELLED"];

const WorkticketInformationWG = ({ refreshWorkticketData }) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { permissions, users } = globalUi;
  const [stateContext, dispatchContext] = useWorkticketView();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [assignedTo, setAssignedTo] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [cancelReasonOptions, setCancelReasonOptions] = useState([]);
  const [cancellationReasonSelected, setCancellationReasonSelected] =
    useState(null);
  const [hasGeo, setHasGeo] = useState(false);
  const [positionLocation, setPositionLocation] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [openCancelUpdate, setOpenCancelUpdate] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [typeValidation, setTypeValidation] = useState(0);
  const [statusUpdateType, setStatusUpdateType] = useState(null);
  const [openMessageError, setOpenMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const [externalData, setExternalData] = useState(null);
  const [externalStatus, setExternalStatus] = useState("");
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationReasonNote, setCancellationReasonNote] = useState("");
  const [externalNote, setExternalNote] = useState("");
  const [isLoadingStatusU, setIsLoadingStatusU] = useState(false);
  const [isLoadingClockOut, setIsLoadingClockOut] = useState(false);
  const [isClosingClockOut, setIsClosingClockOut] = useState(false);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);
  const [disabledStart, setDisabledStart] = useState(false);
  const [activeStart, setActiveStart] = useState(false);
  const [currentClock, setCurrentClock] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [msgValidation, setMsgValidation] = useState(null);
  const [approveValidation, setApproveValidation] = useState(null);
  const [partnerAssigned, setPartnerAssigned] = useState(null);
  const [partners, setPartners] = useState([]);
  const [verifyStage, setVerifyStage] = useState(false);

  const { workticket, isLoading } = stateContext ?? null;

  const usersAssigned = useMemo(() => {
    return workticket?.users?.filter(
      (user) =>
        user.tier_classification !== "subcontractor" &&
        user?.role?.tier_classification !== "subcontractor"
    );
  }, [workticket]);

  const permOnlyAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions),
    [permissions]
  );
  const permAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AP, permissions),
    [permissions]
  );

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          setHasGeo(true);
          setPositionLocation(position);
        }
      } else {
        setHasGeo(false);
      }
    };
    executeGeo();
  }, []);

  useEffect(() => {
    if (workticket?.payment?.user_id && partners.length) {
      const selPartner = partners.find(
        (p) => p.id === workticket.payment.user_id
      );
      setPartnerAssigned(selPartner);
    }
  }, [workticket?.payment?.user_id, partners]);

  useEffect(() => {
    if (partnerAssigned) {
      const usersListInsert = users.filter(
        (user) =>
          (user.tier_classification === "subcontractor_crew" ||
            user?.role?.tier_classification === "subcontractor_crew") &&
          parseFloat(user.crew_id) ===
            parseFloat(partnerAssigned.employee_number)
      );
      setUsersList(usersListInsert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerAssigned]);

  useEffect(() => {
    if (!isLoading && isLoadingUsers) {
      let assigned = [];
      let assignedId = [];
      usersAssigned.map((user) => {
        const userFound = users.find((uItem) => uItem.id === user.id);
        if (userFound) {
          assigned.push(userFound);
        }
        assignedId.push(user.id);
        return true;
      });
      setAssignedTo(assigned);
      let usersListInsert = [];
      if (partnerAssigned) {
        usersListInsert = users.filter(
          (user) =>
            (user.tier_classification === "subcontractor_crew" ||
              user?.role?.tier_classification === "subcontractor_crew") &&
            parseFloat(user.crew_id) ===
              parseFloat(partnerAssigned.employee_number)
        );
      } else {
        usersListInsert = users.filter(
          (user) =>
            user.tier_classification === "subcontractor_crew" ||
            user?.role?.tier_classification === "subcontractor_crew"
        );
      }
      const partnersList = users.filter(
        (user) =>
          user.tier_classification === "subcontractor" ||
          user?.role?.tier_classification === "subcontractor"
      );
      setPartners(partnersList);

      setUsersList(usersListInsert);
      setIsLoadingUsers(false);

      const external = workticket.external?.data;
      if (external) {
        setExternalData(JSON.parse(workticket.external?.data));
      }
      // Cancellation reason options
      const findReasonC = workticketsCancellationReasonOptions.find(
        (item) => item.value === workticket.cancellation_reason
      );
      if (findReasonC || !workticket.cancellation_reason) {
        setCancelReasonOptions(workticketsCancellationReasonOptions);
        if (findReasonC) {
          setCancellationReasonSelected(findReasonC.value);
        }
      } else {
        const insertReasonC = {
          value: workticket.cancellation_reason,
          label: workticket.cancellation_reason,
        };
        setCancelReasonOptions([
          ...workticketsCancellationReasonOptions,
          insertReasonC,
        ]);
        setCancellationReasonSelected(workticket.cancellation_reason);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workticket, isLoading, isLoadingUsers, usersAssigned, assignedTo, users]);

  useEffect(() => {
    // Clock in verification on this workticket
    const runningClocks = async () => {
      if (!isLoading) {
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          const result = await getUserSchedulesAll(user.id);
          const data = result.data.data;
          const entry = data.entries.find(
            (item) =>
              item.workticket.id === workticket.id &&
              !item.clock_hours &&
              item.user.id === user.id
          );

          if (entry && entry.clock_in && entry.user.id === user.id) {
            setActiveStart(true);
            setCurrentClock(entry);
          } else {
            setActiveStart(false);
            setCurrentClock(null);
          }
        } catch (e) {
          logException(e, "No timekeeping");
        }
      }
    };
    if (
      (globalUi.timekeeping && !currentClock) ||
      (!globalUi.timekeeping && currentClock) ||
      currentClock?.workticket?.id !== workticket.id
    ) {
      runningClocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, workticket.id, globalUi.timekeeping]);

  useEffect(() => {
    if (workticket.id) {
      setIsLoadingUsers(true);
      // Workticket ready to be verified as completed
      if (workticket.status === 4) {
        setVerifyStage(true);
      }
    }
  }, [workticket]);

  const handleBlur = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket[event.name] !== event.value) {
      try {
        const data = {
          [event.name]: event.value,
        };
        await updateWorkticketDetails(workticket.id, data);
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: { ...workticket, ...data },
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
        }
        logException(e, "Cannot update workticket");
      }
    }
  };

  const closeMessageError = () => {
    setOpenMessageError(false);
  };

  const handleChangePartnerAssignedTo = async (event, value) => {
    if (value) {
      try {
        const resultChange = await changePartner(workticket.id, value.id);
        if (resultChange) {
          setPartnerAssigned(value);
          await refreshWorkticketData();
        }
      } catch (e) {
        logException(e, "Cannot change partner");
      }
    }
  };

  const handleChangeAssignedTo = async (event, value) => {
    const activeUser = JSON.parse(localStorage.getItem("user"));
    const removeAssigned = [];
    const addAssigned = [];

    const assignedToId = assignedTo.map((item) => item.id);
    const editAssignedToId = value.map((item) => item.id);

    assignedTo.forEach((item) => {
      if (!editAssignedToId.includes(item.id)) {
        removeAssigned.push(item.id);
      }
    });

    value.forEach((item) => {
      if (!assignedToId.includes(item.id)) {
        addAssigned.push(item.id);
      }
    });

    addAssigned.forEach(async (user) => {
      try {
        const resultAssign = await assignUserWorkticket(workticket.id, {
          user_id: user,
        });
        if (resultAssign) {
          await refreshWorkticketData();
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
          const assignedRevert = [];
          usersAssigned.forEach((user) => {
            const userFound = users.find((uItem) => uItem.id === user.id);
            if (userFound) {
              assignedRevert.push(userFound);
            }
          });
          setAssignedTo(assignedRevert);
        }
        logException(e, "Cannot assign user to workticket");
      }
    });

    removeAssigned.forEach(async (user) => {
      try {
        // Safety so subs do not unassign there self
        if (activeUser.id !== user && activeUser.tier_classification !== 15) {
          await removeUserWorkticket(workticket.id, user);
          await refreshWorkticketData();
        } else {
          toast.warning("Can NOT unassign. Please contact account manager.");
          const findUser = usersList.find((item) => item.id === activeUser.id);
          value = [findUser, ...value];
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
          const assignedRevert = [];
          usersAssigned.forEach((user) => {
            const userFound = users.find((uItem) => uItem.id === user.id);
            if (userFound) {
              assignedRevert.push(userFound);
            }
          });
          setAssignedTo(assignedRevert);
        }
        logException(e, "Cannot assign user to workticket");
      }
    });
    setAssignedTo(value);
  };

  const handleStatusUpdate = async () => {
    try {
      setIsLoadingStatusU(true);
      let dataCancellation = {};
      if (
        externalStatus === "COMPLETED-NO CHARGE" ||
        externalStatus === "CANCELLED"
      ) {
        dataCancellation = {
          cancellation_reason: cancellationReason,
          cancellation_note: externalNote,
        };
        setCancellationReasonSelected(cancellationReason);
      }
      let data = {
        external_status: externalStatus,
        external_note: externalNote,
        ...dataCancellation,
      };
      const position = await getGeoLocation();
      if (position) {
        data = {
          ...data,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      await updateWorkticketWGStatus(workticket.id, data);
      await refreshWorkticketData();
      setOpenStatusUpdate(false);
      setExternalStatus("");
      setExternalNote("");
      setStatusUpdateType(null);
      setMsgSuccess("Workticket status has been updated.");
      setOpenSuccess(true);
      setIsLoadingStatusU(false);
    } catch (e) {
      logException(e, "Cannot update workticket status");
    }
  };

  const handleChangCancelStatus = async () => {
    if (
      !hasPermission(
        permissionWorkticket.UPDATE_CANCELLATION_STATUS,
        permissions
      )
    ) {
      return;
    }

    setIsLoadingStatusU(true);
    try {
      await reviewWorkticketCancellation(workticket.id, {
        cancellation_reason: cancellationReason,
        cancellation_note: cancellationReasonNote,
      });
      workticket.cancellation_reviewed = 1;
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
      setIsLoadingStatusU(false);
      setOpenCancelUpdate(false);
    } catch (e) {
      logException(e, "Cannot update workticket cancel status");
    }
  };

  const handleSubmitCancellationReason = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket.cancellation_reason !== event.value) {
      setCancellationReasonSelected(event.value);
      try {
        const data = {
          cancellation_reason: event.value,
        };
        workticket.cancellation_reason = event.value;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketDetails(workticket.id, data);
      } catch (e) {
        logException(e, "Cannot update workticket cancellation reason");
      }
    }
  };

  const handleComplete = async () => {
    try {
      setIsLoadingClockOut(true);
      await handleStopWork();
      setOpenClockOut(false);
      setIsLoadingClockOut(false);
    } catch (e) {
      logException(e, "Cannot mark completed");
    }
  };

  const handleApprove = async () => {
    try {
      setIsLoadingStatusU(true);
      await updateWorkticketWGApprove(workticket.id);
      await refreshWorkticketData();
      setOpenApprove(false);
      setIsLoadingStatusU(false);
    } catch (e) {
      logException(e, "Cannot approve workticket");
    }
  };

  const handleValidationContinue = async () => {
    setIsLoadingValidation(true);
    if (typeValidation === 1) {
      // Clock In
      await handleStartWork(1);
    } else if (typeValidation === 2) {
      // Clock Out
      await handleStopWork(1);
    }
    setOpenValidation(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleChangeStatusWG = (event) => {
    setExternalStatus(event.value);
  };

  const handleChangeNoteWG = (event, value) => {
    setExternalNote(event.value);
  };

  const handleChangeNoteCancellation = (event, value) => {
    setCancellationReasonNote(event.value);
  };

  const handleChangeCancellationReason = (event) => {
    setCancellationReason(event.value);
  };

  const handleStatusOpen = () => {
    setOpenStatusUpdate(true);
    setStatusUpdateType(1);
  };

  const handleStatusCancelOpen = () => {
    setOpenCancelUpdate(true);
  };

  const handleClockOutOpen = () => {
    setExternalStatus("");
    setExternalNote("");
    setOpenClockOut(true);
    setDisabledStart(true);
    setStatusUpdateType(1);
  };

  const handleClockOutCompletedOpen = () => {
    setExternalStatus("");
    setExternalNote("");
    setOpenClockOut(true);
    setStatusUpdateType(2);
  };

  const handleApproveOpen = async () => {
    try {
      let errorApprove = [];
      setOpenApprove(true);
      setIsLoadingStatusU(true);

      const { data } = await getWorkticketDetails(workticket.id);
      const wtNte = workticket.external?.nte ?? 0;
      if (
        (!data.data.workticket.bill.total_amount ||
          Number(data.data.workticket.bill.total_amount) === 0) &&
        wtNte > 0
      ) {
        errorApprove = [...errorApprove, "Missing billing amount (AR)"];
      }

      if (
        !data.data.workticket.payment.total_amount ||
        Number(data.data.workticket.payment.total_amount) === 0
      ) {
        errorApprove = [...errorApprove, "Missing payment amount (AP)"];
      }

      if (
        !data.data.workticket.payment.user_id ||
        data.data.workticket.payment.user_id === 0
      ) {
        errorApprove = [...errorApprove, "Missing partner assignment "];
      }

      setApproveValidation(errorApprove);
      setIsLoadingStatusU(false);
    } catch (e) {
      logException(e, "Cannot approve workticket");
    }
  };

  const handleAccept = async () => {
    console.log("Service Channel Integration");
    setDisabledStart(true);
    try {
      await acceptWorkticketSC(workticket?.id);
      workticket.status = 1;
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
    } catch (e) {
      logException(e, "Cannot accept workorder");
    }
    setDisabledStart(false);
  };

  const handleStatusUpdateClose = () => {
    setOpenStatusUpdate(false);
    setExternalStatus("");
    setExternalNote("");
    setStatusUpdateType(null);
  };

  const handleStatusCancelOpenClose = () => {
    setOpenCancelUpdate(false);
  };

  const handleClockOutClose = () => {
    setOpenClockOut(false);
    setExternalStatus("");
    setExternalNote("");
    setStatusUpdateType(null);
    setIsClosingClockOut(false);
    setDisabledStart(false);
  };

  const handleClockOutConfirmationClose = () => {
    setIsClosingClockOut(true);
  };

  const handleClockOutConfirmationRevert = () => {
    setIsClosingClockOut(false);
  };

  const handleApproveClose = () => {
    setOpenApprove(false);
  };

  const handleValidationClose = () => {
    setOpenValidation(false);
    setIsLoadingValidation(false);
    setDisabledStart(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
    setDisabledStart(false);
  };

  const handleStartWork = async (skipValFlag = 0) => {
    setDisabledStart(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserJobSchedules(
      user.id,
      workticket.job_number
    );

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }

    try {
      const data = {
        drive: 0,
        skipFlag: skipValFlag,
        latitude: positionLocation ? positionLocation.coords.latitude : null,
        longitude: positionLocation ? positionLocation.coords.longitude : null,
      };

      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. This will result in ticket not being verified as completed at site.",
        });
        setTypeValidation(1);
        setOpenValidation(true);
        return;
      }

      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 800 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(1);
          setOpenValidation(true);
          return;
        }
      }

      const result = await clockInWorkticketNonStrict(workticket.id, data);
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        setCurrentClock(entry);
        const entryInput = { ...entry, workticket: { ...workticket } };
        dispatchGlobalUi({
          type: "SET_TIMEKEEPING",
          timekeeping: entryInput,
        });
      }
      setActiveStart(true);
      setDisabledStart(false);
      setIsLoadingValidation(false);
    } catch (e) {
      const daysDiff = DaysFromNowStandard(workticket.start_date);
      let errorMsg = e.response.data.message;
      if (daysDiff > 0) {
        errorMsg =
          "This ticket is overdue, and past the acceptable timeframe of completion. Please reach out to Encompass Support to update the status.";
      }
      setMsgError(errorMsg);
      setOpenError(true);
    }
  };

  const handleStopWork = async (skipValFlag = 0) => {
    try {
      if (!activeStart) {
        toast.warning("No clock is running.");
        return;
      }

      const data = {
        drive: 0,
        skipFlag: skipValFlag,
        latitude: positionLocation ? positionLocation.coords.latitude : null,
        longitude: positionLocation ? positionLocation.coords.longitude : null,
        status:
          externalStatus && externalStatus !== ""
            ? externalStatus
            : "Completed",
        resolution: externalNote,
      };

      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. This will result in ticket not being verified as completed at site.",
        });
        setTypeValidation(2);
        setOpenValidation(true);
        return;
      }

      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 800 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(2);
          setOpenValidation(true);
          return;
        }
      }

      setDisabledStart(true);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );

      setActiveStart(false);
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }

      if (data.status === "Completed") {
        // Mark ticket as work completed
        workticket.status = 4;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
      }
      setDisabledStart(false);
      setIsLoadingValidation(false);
      setExternalStatus("");
      setExternalNote("");
    } catch (e) {
      logException(e, "Cannot stop work");
    }
  };

  if (isLoading || isLoadingUsers) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  const readOnlyWorkticket =
    (workticket?.recurrence_instance_id && !workticket?.instance_override) ||
    [2, 3, 5].includes(workticket?.status);

  return (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="workticket_number"
            label="Workticket Number"
            value={workticket.number}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          <WorkticketBillingPartner onlyPortfolio={true} />
          {!permOnlyAP && !permAP ? null : (![2, 3, 4, 5].includes(
              workticket.status
            ) ||
              (workticket.status === 2 &&
                workticket?.payment?.amount_paid === 0 &&
                [0, 1].includes(workticket.payment.status))) &&
            hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions) &&
            hasPermission(permissionWorkticket.EDIT, permissions) ? (
            <FormSelectChipsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={partners}
              name="partner"
              label="Partner Responsible"
              handleChange={handleChangePartnerAssignedTo}
              value={partnerAssigned}
              readonly={readOnlyWorkticket}
            />
          ) : (
            <>
              <InputLabel className={classes.inputLabel}>
                Partner Responsible
              </InputLabel>

              {Boolean(partnerAssigned) ? (
                <Box className={classes.chipContainer}>
                  <Chip
                    disabled={false}
                    data-tag-index={1}
                    tabIndex={-1}
                    avatar={<Avatar>{partnerAssigned?.first_name[0]}</Avatar>}
                    label={`${partnerAssigned?.first_name} ${partnerAssigned?.last_name}`}
                    className={classes.chipAutoDis}
                  />
                </Box>
              ) : (
                <Chip
                  disabled={false}
                  data-tag-index={1}
                  tabIndex={-1}
                  avatar={<Avatar></Avatar>}
                  label="N/A"
                  className={classes.chipAuto}
                />
              )}
            </>
          )}
          {(![2, 3, 4, 5].includes(workticket.status) ||
            (workticket.status === 2 &&
              workticket?.payment?.amount_paid === 0 &&
              [0, 1].includes(workticket.payment.status))) &&
          hasPermission(permissionWorkticket.ASSIGN_SCHEDULE, permissions) ? (
            <FormSelectChipsAuto
              gridSizes={[{ size: "md", val: 12 }]}
              options={usersList}
              name="users"
              label="Assigned To"
              handleChange={handleChangeAssignedTo}
              multiple={true}
              value={assignedTo}
              readonly={readOnlyWorkticket}
            />
          ) : (
            <>
              {Boolean(assignedTo.length) ? (
                <>
                  <InputLabel className={classes.inputLabel}>
                    Assigned To
                  </InputLabel>
                  <Box className={classes.chipContainer}>
                    {assignedTo.map((option, index) => (
                      <Chip
                        disabled={false}
                        key={index}
                        data-tag-index={index}
                        tabIndex={-1}
                        avatar={<Avatar>{option?.first_name[0]}</Avatar>}
                        label={`${option?.first_name} ${option?.last_name}`}
                        className={classes.chipAutoDisMultiple}
                      />
                    ))}
                  </Box>
                </>
              ) : null}
            </>
          )}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            value={workticket.subject}
            handleBlur={handleBlur}
            readonly={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            value={workticket?.description ?? ""}
            multiline={true}
            rows={4}
            handleBlur={handleBlur}
            readonly={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
          {workticket.workticket_category === 4 && (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="external_woid"
              label="WOID"
              value={workticket.external_woid}
              handleBlur={handleBlur}
              readonly={!hasPermission(permissionWorkticket.EDIT, permissions)}
            />
          )}
          <Box className={classes.containerPriority}>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={6}>
                <WorkticketSoloStatusChip
                  status={workticket.status}
                  type={workticket.type}
                  category={workticket.workticket_category}
                  cancellation_reviewed={workticket.cancellation_reviewed}
                />
              </Grid>
              <Grid item xs={6}>
                {![2, 3, 5].includes(workticket.status) &&
                hasPermission(
                  permissionWorkticket.WALGREENS_STATUS_CHANGE,
                  permissions
                ) ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={true}
                    className={classNames(
                      classes.button,
                      classes.buttonOutlined
                    )}
                    onClick={handleStatusOpen}
                  >
                    Update Status
                  </Button>
                ) : null}
                {[3].includes(workticket.status) &&
                Number(workticket.cancellation_reviewed) === 0 &&
                hasPermission(
                  permissionWorkticket.UPDATE_CANCELLATION_STATUS,
                  permissions
                ) ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={true}
                    className={classNames(
                      classes.button,
                      classes.buttonOutlined
                    )}
                    onClick={handleStatusCancelOpen}
                    style={{ marginBottom: 3 }}
                  >
                    Cancel WT
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          {[3].includes(workticket.status) ? (
            <FormSelect
              gridSizes={[{ size: "md", val: 12 }]}
              name="cancellation_reason"
              label="Cancellation Reason"
              options={cancelReasonOptions}
              value={cancellationReasonSelected}
              handleBlur={handleSubmitCancellationReason}
              changeValueControlled={true}
              readonly={
                !hasPermission(
                  permissionWorkticket.UPDATE_CANCELLATION_STATUS,
                  permissions
                )
              }
            />
          ) : null}
          {hasPermission(
            permissionWorkticket.REQUESTS_VIEW_LINK,
            permissions
          ) ? (
            <>
              <InputLabel className={classes.inputLabel}>
                Workorder Number
              </InputLabel>
              <Typography className={classes.linkTipo}>
                <a
                  href={
                    window.location.hostname === "app.encompassonsite.com"
                      ? `https://www.servicechannel.com/sc/wo/Workorders/index?id=${workticket.external?.number}`
                      : `https://sb2.servicechannel.com/sc/wo/Workorders/index?id=${workticket.external?.number}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={classes.linkLabel}
                >
                  {workticket.external?.number}
                  <IconLink className={classes.linkIcon} />
                </a>
              </Typography>
            </>
          ) : null}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_number"
            label="Job"
            value={`${workticket.job.job_number} - ${workticket.job.job_description}`}
            readonly={true}
          />
          {workticket.job_zone ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="job_zone"
              label="Campus Zone"
              value={workticket.job_zone.name}
              readonly={true}
            />
          ) : null}
          {workticket.area ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="area"
              label="Area"
              value={workticket.area}
              readonly={true}
            />
          ) : null}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="created_at"
            label="Created Date"
            value={convertDateFormatField(workticket.created_at)}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          {workticket?.external_start_date ? (
            <FormInput
              gridSizes={[{ size: "md", val: 12 }]}
              name="external_start_date"
              label="Start Date"
              value={convertDateFormatField(workticket.external_start_date)}
              styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
              readonly={true}
            />
          ) : null}
          <FormInputDateAdo
            name="start_date"
            label="Due Date"
            value={workticket.start_date}
            disabled={true}
          />
          {externalData &&
          hasPermission(permissionWorkticket.WALGREENS_DATA, permissions) ? (
            <Box className={classes.containerInformationExternal}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_type"
                label="Type"
                value={
                  workticket.external.type === 1 ? "Preventative" : "Reactive"
                }
                readonly={true}
              />
              {workticket.external.type === 1 ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="call_date"
                  label="Call Date"
                  value={convertDateFormatField(workticket.external.call_date)}
                  styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
                  readonly={true}
                />
              ) : null}

              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_category"
                label="Category"
                value={externalData.Category.toLowerCase()}
                readonly={true}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_trade"
                label="Trade"
                value={externalData.Trade.toLowerCase()}
                readonly={true}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_status"
                label="Status"
                value={`${externalData.Status.Primary.toLowerCase()} ${
                  externalData.Status.Extended
                    ? ` - ${externalData.Status.Extended.toLowerCase()}`
                    : ""
                }`}
                readonly={true}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_priority"
                label="Priority"
                value={externalData.Priority.toLowerCase()}
                readonly={true}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="site_number"
                label="Site Number"
                value={externalData.Location.StoreId}
                readonly={true}
              />
              {hasPermission(
                permissionWorkticket.WALGREENS_NTE,
                permissions
              ) ? (
                <>
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="nte"
                    label="NTE"
                    value={externalData.Nte}
                    readonly={true}
                  />
                  {externalData.Price ? (
                    <FormInput
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="price"
                      label="Price"
                      value={externalData.Price}
                      readonly={true}
                    />
                  ) : null}
                </>
              ) : null}
            </Box>
          ) : null}
        </Box>
        <Box className={classes.containerInformationActions}>
          {![2, 3, 4, 5].includes(workticket.status) ? (
            <Grid container spacing={1} justifyContent="flex-end">
              {workticket.external.approval === 1 ? (
                <>
                  <Grid item xs={6}>
                    {activeStart ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth={true}
                        onClick={handleClockOutOpen}
                        className={classes.buttonStop}
                        disabled={disabledStart}
                        startIcon={hasGeo ? <LocationIcon /> : null}
                      >
                        Stop
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth={true}
                        onClick={() => handleStartWork()}
                        className={classes.buttonStart}
                        disabled={disabledStart}
                        startIcon={hasGeo ? <LocationIcon /> : null}
                      >
                        Start
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      onClick={handleClockOutCompletedOpen}
                      className={classes.buttonCompleted}
                      disabled={!activeStart}
                    >
                      Mark Completed
                    </Button>
                  </Grid>
                </>
              ) : hasPermission(permissionWorkticket.REQUESTS, permissions) &&
                workticket.status !== 6 &&
                workticket.external.type === 2 ? (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth={true}
                    onClick={handleApproveOpen}
                    className={classes.buttonCompleted}
                  >
                    Approve
                  </Button>
                </Grid>
              ) : hasPermission(permissionWorkticket.REQUESTS, permissions) &&
                workticket.status === 6 &&
                workticket.external.type === 2 ? (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={handleAccept}
                    className={classes.buttonAccept}
                    disabled={disabledStart}
                  >
                    Accept
                  </Button>
                </Grid>
              ) : hasPermission(
                  permissionWorkticket.WORKTICKETS_PREVIEW,
                  permissions
                ) && workticket.external.type === 1 ? (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={true}
                    className={classes.buttonCompleted}
                    disabled={true}
                  >
                    Preview
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          ) : null}
          <ErrorMessageDialog
            title={""}
            open={openMessageError}
            handleClose={closeMessageError}
            message={message}
          />
        </Box>
      </Box>

      <Dialog
        open={openStatusUpdate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingStatusU ? (
            <>
              <DialogCloseIcon handleClose={handleStatusUpdateClose} />
              <DialogTitle title="Workticket" icon={<TicketIcon />} />
              <Box className={classes.wrapperDialogStatus}>
                {statusUpdateType === 1 && !verifyStage ? (
                  <FormSelect
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_status"
                    label="Status(*)"
                    options={workticketStatusWG}
                    value={externalStatus}
                    handleBlur={handleChangeStatusWG}
                    changeValueControlled={true}
                  />
                ) : null}
                {statusUpdateType === 1 && verifyStage ? (
                  <FormSelect
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_status"
                    label="Status(*)"
                    options={workticketStatusWGVerify}
                    value={externalStatus}
                    handleBlur={handleChangeStatusWG}
                    changeValueControlled={true}
                  />
                ) : null}
                {cancelledStatus.includes(externalStatus) ? (
                  <FormSelect
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="cancellation_reason"
                    label="Reason(*)"
                    options={workticketsCancellationReasonOptions}
                    value={cancellationReason}
                    handleBlur={handleChangeCancellationReason}
                    changeValueControlled={true}
                  />
                ) : null}

                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="external_note"
                  label="Note"
                  value={externalNote}
                  multiline={true}
                  rows={4}
                  handleBlur={handleChangeNoteWG}
                />
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingStatusU ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleStatusUpdateClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="primary"
              disabled={isLoadingStatusU}
            >
              Cancel
            </Button>
            <Button
              onClick={handleStatusUpdate}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingStatusU ||
                !externalStatus ||
                ((externalStatus === "COMPLETED-NO CHARGE" ||
                  externalStatus === "CANCELLED") &&
                  !cancellationReason)
              }
            >
              {statusUpdateType === 2 ? "Completed" : "Update"}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openCancelUpdate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingStatusU ? (
            <>
              <DialogCloseIcon handleClose={handleStatusCancelOpenClose} />
              <DialogTitle title="Workticket" icon={<TicketIcon />} />
              {workticket.cancellation_reason ||
              workticket.cancellation_note ? (
                <Box className={classes.wrapperDialogStatusCurrent}>
                  <Typography variant="body1" gutterBottom>
                    <b>Current reason:</b> {workticket.cancellation_reason}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b>Current note:</b> {workticket.cancellation_note}
                  </Typography>
                </Box>
              ) : null}
              <Box className={classes.wrapperDialogStatus}>
                <FormSelect
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="cancellation_reason"
                  label="Reason(*)"
                  options={workticketsCancellationReasonOptions}
                  value={cancellationReason}
                  handleBlur={handleChangeCancellationReason}
                  changeValueControlled={true}
                />
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="cancellation_reason_note"
                  label="Note(*)"
                  placeholder="Type note here"
                  value={cancellationReasonNote}
                  multiline={true}
                  rows={4}
                  handleBlur={handleChangeNoteCancellation}
                />
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingStatusU ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleStatusCancelOpenClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="primary"
              disabled={isLoadingStatusU}
            >
              Cancel
            </Button>
            <Button
              onClick={handleChangCancelStatus}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingStatusU ||
                !cancellationReason ||
                !cancellationReasonNote
              }
            >
              Confirm
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openClockOut}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingClockOut ? (
            <>
              {!isClosingClockOut ? (
                <DialogCloseIcon
                  handleClose={handleClockOutConfirmationClose}
                />
              ) : null}
              <DialogTitle
                title={`Workticket - ${workticket.number}`}
                icon={<TicketIcon />}
              />
              {!isClosingClockOut ? (
                <Box className={classes.wrapperDialogStatus}>
                  {statusUpdateType === 1 ? (
                    <>
                      <Box className={classes.wrapperDialogValidation}>
                        <Typography variant="body2">
                          Please update the status of the workticket
                        </Typography>
                      </Box>
                      <FormSelect
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="external_status_clock_out"
                        label="Select status"
                        options={workticketClockOutWG}
                        value={externalStatus}
                        handleBlur={handleChangeStatusWG}
                        changeValueControlled={true}
                      />
                    </>
                  ) : null}
                  {statusUpdateType === 2 ? (
                    <Box className={classes.wrapperDialogValidation}>
                      <Typography variant="body2">
                        Would you like to add a note, before marking the
                        workticket as completed?
                      </Typography>
                    </Box>
                  ) : null}

                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_note"
                    label="Note"
                    placeholder="Type note here"
                    value={externalNote}
                    multiline={true}
                    rows={4}
                    handleBlur={handleChangeNoteWG}
                  />
                </Box>
              ) : (
                <Box className={classes.wrapperDialogStatus}>
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Are you sure you want to cancel, the status of the
                      workticket won't be updated?
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingClockOut && !isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleComplete}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingClockOut || (statusUpdateType === 1 && !externalStatus)
              }
            >
              Submit
            </Button>
          </DialogActions>
        ) : null}
        {isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClockOutConfirmationRevert}
              className={classes.buttonOutlinedDialog}
              color="secondary"
              variant="outlined"
              size="large"
            >
              No
            </Button>
            <Button
              onClick={handleClockOutClose}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Yes
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openValidation}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingValidation ? (
            <>
              <DialogCloseIcon handleClose={handleValidationClose} />
              <DialogTitle
                title={`Location ${msgValidation?.title}`}
                icon={<LocationOff fontSize="large" />}
              />
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  {msgValidation?.content}
                </Typography>
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingValidation ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleValidationClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="secondary"
            >
              Go Back
            </Button>
            <Button
              onClick={handleValidationContinue}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Continue
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openApprove}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingStatusU ? (
            <>
              <DialogCloseIcon handleClose={handleApproveClose} />
              <DialogTitle title="Workticket" icon={<TicketIcon />} />
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  Please verify the partner, partner payment and billing are
                  correct. This will enable the workticket for work.
                </Typography>
              </Box>
              {approveValidation?.map((item, index) => (
                <Box
                  key={`ai-${index}`}
                  className={classes.wrapperItemValidation}
                >
                  * {item}
                </Box>
              ))}
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingStatusU ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleApproveClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="primary"
              disabled={isLoadingStatusU}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApprove}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingStatusU ||
                (approveValidation && Boolean(approveValidation.length))
              }
            >
              {Boolean(approveValidation?.length) ? "*" : ""}
              Approve
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={msgSuccess}
      />
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default withRouter(WorkticketInformationWG);
