import React from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import NotesTable from "./notesTable";
import { NoteProvider } from "contexts/noteContext";
import withTableFilterContext from "components/hoc/withTableFilterContext";
import Typography from "@material-ui/core/Typography";
import { useJobView } from "contexts/jobViewContext";
import useStyles from "./styles";

const NotesList = (props) => {
  const [stateContext] = useJobView();
  const { isLoading, job } = stateContext ? stateContext : null;
  const classes = useStyles();

  if (isLoading && job !== "new") {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }
  return (
    <>
      <Box className={classes.pageBodyWrapper}>
        <NoteProvider>
          <NotesTable />
        </NoteProvider>
      </Box>
    </>
  );
};

export default withTableFilterContext(NotesList);
