import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import TimelineIcon from "@material-ui/icons/Timeline";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import { assign } from "lodash";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionAdmin, hasPermission } from "lib/permissions";

import {
  addWorkticketComment,
  updateWorkticketComment,
  deleteWorkticketComment,
} from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { convertDateFormatField } from "components/util/timeFormat";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketActivity = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [tab, setTab] = useState(0);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [pastComment, setPastComment] = useState(null);
  const [editComment, setEditComment] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoadingComment, setIsLoadingComment] = useState(false);

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      setCommentList(assign(workticket.comments, { isEditable: false }));
      setActivityList(workticket.activity);
    }
  }, [isLoading, workticket.comments, workticket.activity]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleAddComment = async () => {
    if (comment !== "") {
      const data = {
        body: comment,
      };
      try {
        setIsLoadingComment(true);
        const result = await addWorkticketComment(workticket.id, data);
        setCommentList(
          assign(result.data.data.workticket.comments, { isEditable: false })
        );
        setComment("");
        setIsLoadingComment(false);
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleClickItem = (event, comment) => {
    setAnchorEl(event.currentTarget);
    setPastComment(selectedComment);
    setSelectedComment(comment);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsEdit = () => {
    if (pastComment) {
      pastComment.isEditable = false;
    }
    selectedComment.isEditable = true;
    setEditComment(selectedComment.body);
    setAnchorEl(null);
  };

  const openDelete = () => {
    setAnchorEl(null);
    setOpenConfirm(true);
  };

  const handleCancelEdit = () => {
    selectedComment.isEditable = false;
    setPastComment(null);
    setSelectedComment(null);
  };

  const handleUpdateComment = async () => {
    const data = {
      body: editComment,
    };
    try {
      setIsLoadingComment(true);
      await updateWorkticketComment(workticket.id, selectedComment.id, data);
      selectedComment.isEditable = false;
      selectedComment.body = editComment;
      setPastComment(null);
      setSelectedComment(null);
      setIsLoadingComment(false);
    } catch (e) {
      logException(e, "Cannot update comment");
    }
  };

  const deleteComment = async () => {
    try {
      await deleteWorkticketComment(workticket.id, selectedComment.id);
      const comments = commentList.filter((c) => c.id !== selectedComment.id);
      setOpenConfirm(false);
      setCommentList(comments);
      setPastComment(null);
      setSelectedComment(null);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const ActivityItem = (props) => (
    <ListItem className={classes.listContainer}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listText}
        secondary={props.activity.activity_message}
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(
          props.activity.created_at,
          workticket?.job?.timezone
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyActivity}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        className={classes.activityTabRoot}
      >
        <Tab
          label={`Comments (${commentList.length})`}
          value={0}
          className={classes.activityTab}
        />
        <Tab
          label={`Activity (${activityList.length})`}
          value={1}
          className={classes.activityTab}
        />
      </Tabs>
      {tab === 0 && (
        <Box className={classes.containerComments}>
          <List className={classes.containerCommentsList}>
            {commentList.map((comment) => (
              <ListItem
                key={comment.id}
                className={
                  comment.isEditable
                    ? classes.listItemComment
                    : classes.listContainer
                }
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatarComment}>
                    {comment.user[0].first_name.charAt(0)}
                    {comment.user[0].last_name.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                {comment.isEditable ? (
                  <ListItemText
                    disableTypography={true}
                    className={classes.listText}
                    secondary={
                      <Box className={classes.containerCommentsForm}>
                        <Input
                          className={classes.inputEditComment}
                          disableUnderline={true}
                          rows={6}
                          multiline={true}
                          fullWidth={true}
                          autoFocus={true}
                          value={editComment}
                          onChange={(e) => setEditComment(e.target.value)}
                        />
                        <Box className={classes.boxActionsComment}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            disableElevation
                            className={classNames(
                              classes.button,
                              classes.buttonOutlinedComment
                            )}
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            className={classes.button}
                            onClick={handleUpdateComment}
                            disabled={isLoadingComment}
                          >
                            Update Comment
                          </Button>
                        </Box>
                      </Box>
                    }
                  />
                ) : (
                  <ListItemText
                    className={classes.listText}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.listName}
                          color="textPrimary"
                        >
                          {comment.user[0].first_name}{" "}
                          {comment.user[0].last_name}
                        </Typography>
                        {comment.body}
                      </React.Fragment>
                    }
                  />
                )}
                {!comment.isEditable && (
                  <ListItemSecondaryAction className={classes.listAction}>
                    {convertDateFormatField(
                      comment.created_at,
                      workticket?.job?.timezone
                    )}
                    {hasPermission(
                      permissionAdmin.COMMENTS_MANAGE,
                      permissions
                    ) && (
                      <IconButton
                        aria-controls="wt-comment-menu"
                        aria-label="comment-edit"
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleClickItem(event, comment);
                        }}
                        className={classes.menuItemIconComment}
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
          <Box className={classes.containerCommentsForm}>
            <Input
              className={classes.inputNewComment}
              disableUnderline={true}
              rows={6}
              multiline={true}
              fullWidth={true}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Box className={classes.boxActions}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableElevation
                className={classNames(classes.button)}
                onClick={handleAddComment}
                disabled={isLoadingComment}
              >
                Add Comment
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {tab === 1 && (
        <Box className={classes.containerActivity}>
          <List className={classes.containerActivityList}>
            {activityList.map((activity) => (
              <ActivityItem key={activity.id} activity={activity} />
            ))}
          </List>
        </Box>
      )}
      <Menu
        id="quote-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          className: classes.menu,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            markAsEdit();
          }}
          className={classes.menuItem}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDelete();
          }}
          className={classes.menuItem}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={openConfirm}
        handleConfirm={deleteComment}
        handleClose={closeConfirm}
        message={"Are you sure you want to delete this comment?"}
        title={`Workticket ${workticket.number}`}
      />
    </Box>
  );
};

export default WorkticketActivity;
