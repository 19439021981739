import React, { useContext, useReducer } from "react";

const WorkticketViewContext = React.createContext();

const initialState = {
  workticket: {},
  isLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "SET_WORKTICKET":
      return {
        ...state,
        workticket: action.workticket,
      };
    default:
      return state;
  }
};

const WorkticketViewProvider = ({ children }) => (
  <WorkticketViewContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </WorkticketViewContext.Provider>
);

const useWorkticketView = () => {
  const context = useContext(WorkticketViewContext);
  if (context === undefined) {
    throw new Error(
      "useWorkticketView must be used within a WorkticketViewProvider"
    );
  }
  return context;
};

export { WorkticketViewProvider, useWorkticketView };
