import React, { useState, useEffect } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { assign } from "lodash";

import {
  addWorkticketComment,
  updateWorkticketComment,
} from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { convertDateFormatField } from "components/util/timeFormat";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const WorkticketActivity = (props) => {
  const classes = useStyles();
  const [stateContext] = useWorkticketView();
  const [tab, setTab] = useState(0);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);

  const [selectedComment, setSelectedComment] = useState(null);
  const [editComment, setEditComment] = useState("");

  const { workticket, isLoading } = stateContext ?? null;

  useEffect(() => {
    if (!isLoading) {
      setCommentList(assign(workticket.comments, { isEditable: false }));
    }
  }, [isLoading, workticket.comments, workticket.activity]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleAddComment = async () => {
    if (comment !== "") {
      const data = {
        body: comment,
      };
      try {
        const result = await addWorkticketComment(workticket.id, data);
        setCommentList(
          assign(result.data.data.workticket.comments, { isEditable: false })
        );
        setComment("");
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleCancelEdit = () => {
    selectedComment.isEditable = false;
    setSelectedComment(null);
  };

  const handleUpdateComment = async () => {
    const data = {
      body: editComment,
    };
    try {
      await updateWorkticketComment(workticket.id, selectedComment.id, data);
      selectedComment.isEditable = false;
      selectedComment.body = editComment;
      setSelectedComment(null);
    } catch (e) {
      logException(e, "Cannot update comment");
    }
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyActivity}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        className={classes.activityTabRoot}
      >
        <Tab
          label={`Comments (${commentList.length})`}
          value={0}
          className={classes.activityTab}
        />
      </Tabs>
      {tab === 0 && (
        <Box className={classes.containerComments}>
          <List className={classes.containerCommentsList}>
            {commentList.map((comment) => (
              <ListItem
                key={comment.id}
                className={
                  comment.isEditable
                    ? classes.listItemComment
                    : classes.listContainer
                }
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatarComment}>
                    {comment.user[0].first_name.charAt(0)}
                    {comment.user[0].last_name.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                {comment.isEditable ? (
                  <ListItemText
                    disableTypography={true}
                    className={classes.listText}
                    secondary={
                      <Box className={classes.containerCommentsForm}>
                        <Input
                          className={classes.inputEditComment}
                          disableUnderline={true}
                          rows={6}
                          multiline={true}
                          fullWidth={true}
                          autoFocus={true}
                          value={editComment}
                          onChange={(e) => setEditComment(e.target.value)}
                        />
                        <Box className={classes.boxActionsComment}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            disableElevation
                            className={classNames(
                              classes.button,
                              classes.buttonOutlinedComment
                            )}
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            className={classNames(
                              classes.button,
                              classes.buttonAdd
                            )}
                            onClick={handleUpdateComment}
                          >
                            Update Comment
                          </Button>
                        </Box>
                      </Box>
                    }
                  />
                ) : (
                  <ListItemText
                    className={classes.listText}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.listName}
                          color="textPrimary"
                        >
                          {comment.user[0].first_name}{" "}
                          {comment.user[0].last_name}
                        </Typography>
                        {comment.body}
                      </React.Fragment>
                    }
                  />
                )}
                {!comment.isEditable && (
                  <ListItemSecondaryAction className={classes.listAction}>
                    {convertDateFormatField(comment.created_at)}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
          <Box className={classes.containerCommentsForm}>
            <Input
              className={classes.inputNewComment}
              disableUnderline={true}
              rows={6}
              multiline={true}
              fullWidth={true}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Box className={classes.boxActions}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                className={classNames(classes.button, classes.buttonAdd)}
                onClick={handleAddComment}
              >
                Add Comment
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WorkticketActivity;
