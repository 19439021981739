import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
    overflow: "auto",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  tablerow: {
    "&:hover": {},
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    height: "80vh",
    maxWidth: "100%",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "97%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  tablecell: {},
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  wrapperTable: {
    paddingTop: theme.spacing(2),
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    // [theme.breakpoints.up("sm")]: {
    //   marginRight: 70,
    // },
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    minWidth: "420px",
  },
  detailWrapper: {
    padding: "20px 50px 70px 20px",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
  },
  detailTabbed: {
    borderTop: "solid 1px #ececec",
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "15px",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    textAlign: "end",
    position: "relative",
    top: 40,
    marginTop: -20,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tabItem: {
    display: "block",
  },
  iconQuestionDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  popover: {
    pointerEvents: "none",
  },
  wrapperName: {
    paddingLeft: theme.spacing(4),
    position: "relative",
    minWidth: 140,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    minHeight: 41,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonPrimaryTable: {
    color: "#4f98bc",
    background: "#ffffff",
    fontSize: 12,
    height: 22,
  },
  buttonTable: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: "bold",
    minWidth: 100,
    maxHeight: 22,
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  labelEmpty: {
    textAlign: "center",
  },
  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
  },
  marginLeft10: {
    marginLeft: 10,
  },
  chipFontColor: {
    color: "#ffffff",
    textAlign: "center",
    padding: "2px",
    paddingLeft: "30px",
    paddingRight: "30px",
    fontSize: "12px",
  },
  label: {
    textAlign: "left",
    fontSize: "14px",
    color: "#747474",
    fontWeight: "bold",
  },
  labelLeft: {
    textAlign: "left",
    color: "#C4C4C4",
    fontSize: "14px",
    fontSize: "14px",
    color: "#747474",
    fontWeight: "bold",
  },
  fullWidth: {
    width: "100%",
  },
  marginTop10: {
    marginTop: "10px",
  },
  rightAlignBtn: {
    justifyContent: "flex-end",
  },
  centerAlignBtn: {
    justifyContent: "center",
  },
  leftGridBg: {
    backgroundColor: "#FBFBFB",
  },
  transparentBtn: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
  },
  columnContainerLeft: {
    overflow: "auto !important",
    height: "100%",
    backgroundColor: "#FBFBFB",
  },
  columnLeftButton: {
    backgroundColor: "#ECECEC",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  columnRightBg: {
    backgroundColor: "#FBFBFB",
  },
  docsContainer: {
    backgroundColor: "#ffffff",
    height: "262px",
  },
  docsFont: {
    fontSize: "18px",
  },
  drive: {
    marginTop: "67px",
  },
  driveBg: {
    color: "#C4C4C4",
  },
  dragDocs: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#C4C4C4",
    textAlign: "center",
  },
  browse: {
    fontSize: "12px",
    color: "#4F98BC",
    textDecoration: "underline",
    textAlign: "center",
  },
  driveDropbox: {
    flex: "1",
    fontSize: "10px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #C4C4C4",
    paddingTop: "2px",
  },
  talentRequestLabel: {
    color: "#747474",
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "15px",
  },
  input: {
    color: "rgb(118, 119, 123)",
    border: "1px solid rgb(214, 221, 225)",
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2,
  },
  overlay: {
    position: "absolute",
    zIndex: 3,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(0 0 0 / 48%)",
  },
  zIndex0: {
    zIndex: 0,
  },
  flex1marginRight5: {
    flex: "1",
    marginRight: "5px",
  },
  hoursLunch: {
    paddingTop: "9px",
    paddingLeft: "9px",
    paddingRight: "9px",
  },
  paddingRight5: {
    paddingRight: "5px",
  },
  paddingLeft5: {
    paddingLeft: "5px",
  },
  absCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  step: {
    backgroundColor: "#F8F8F8",
    padding: "23px",
  },
  currentTab: {
    textAlign: "right",
    color: "#747474",
    fontSize: "14px",
    fontWeight: "bold",
  },
  talentRequest: { fontSize: 18, fontWeight: "normal", marginBottom: 15 },
  tellMore: { color: "#747474", fontSize: "14px" },
  padding23: { padding: "23px" },
  schedulerText: { color: "#4A4A4A", fontSize: "10px", fontWeight: "bold" },
  schedulerContainer: {
    backgroundColor: "#ffffff",
    padding: "20px",
  },
  workSchedule: {
    textAlign: "center",
    color: "#747474",
    fontSize: "14px",
    fontWeight: "bold",
  },
  descIcon: { display: "flex", justifyContent: "center", color: "#4D4D4D" },
  enterExpected: { textAlign: "center", fontSize: "14px" },
  startRow: { borderBottom: "1px solid #E6E5E5" },
  flex1Type: {
    margin: -8,
    marginLeft: -3,
    flex: "1",
    paddingRight: "7px",
  },
  textArea: { resize: "none", outline: "none", width: "325px" },
  trContainer: {
    // position: "fixed",
    bottom: 200,
    width: 350,
    display: "flex",
    marginBottom: 15,
  },
  trSubContainer: {
    flex: "1",
    color: "#4F98BC",
    display: "flex",
    marginTop: 11,
    marginLeft: 45,
    pointer: "cursor",
  },
  trPointer: { cursor: "pointer" },
  nxtbuttonContainer: { flex: "1", justifyContent: "center" },
  trSidebar: {
    position: "fixed",
    right: "0px",
    top: "0px",
    backgroundColor: "#ffffff",
    zIndex: 3,
    minHeight: "100%",
    border: "1px solid #F8F8F8",
    width: "455px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  radiusChip: { borderRadius: "3px", maxWidth: "200px" },
  reqType: {
    backgroundColor: "#ffffff",
    padding: "20px",
    width: "250px",
  },
  closeIcon: {
    marginLeft: -27,
    marginTop: -22,
  },
  flex2: {
    flex: "2",
  },
  viewChip: {
    display: "inline-block",
    maxWidth: 135,
    position: "relative",
    top: "-4px",
    marginLeft: 10,
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
    },
  },
  buttonUpload: {
    background: "#4f98bc !important",
    width: 160,
    marginTop: 10,
  },
  containerUploadFile: {
    textAlign: "right",
  },
  containerDisplayFiles: {
    marginTop: 10,
  },
  containerFile: {
    backgroundColor: "#ffffff",
    paddingTop: 10,
    marginBottom: 30,
  },
  titleSectionBody: {
    fontSize: "18px",
  },
  openPosView: {
    paddingTop: 6,
    paddingBottom: 15,
  },
  commentsBg: {
    color: "#4F98BC",
    textAlign: "left",
    fontSize: "14px",
  },
  weeklyScheduleView: { backgroundColor: "#FBFBFB", padding: "20px" },
  inputView: {
    textAlign: "left",
    fontSize: "14px",
    color: "#747474",
    fontWeight: "bold",
  },
  inputViewSubmit: {
    paddingTop: "15px",
    textAlign: "left",
    fontSize: "14px",
    color: "#747474",
    fontWeight: "bold",
  },
  updateBtnView: {
    marginBottom: "10px",
  },
  copyToAll: {
    textAlign: "center",
    fontSize: 12,
    color: "#ffffff",
    backgroundColor: "#4F98BC",
    padding: 1,
    paddingRight: 3,
    // marginLeft:"40px",
    marginTop: 5,
    borderRadius: 3,
    cursor: "pointer",
  },
  copyToAllView: {
    color: "#ffffff",
    cursor: "pointer",
    padding: 1,
    fontSize: 12,
    marginTop: 5,
    textAlign: "center",
    // marginLeft:"30px",
    borderRadius: 3,
    paddingRight: 3,
    backgroundColor: "#4F98BC",
    position: "relative",
  },
  viewLocationButton: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
    width: "100%",
    marginTop: 10,
    backgroundColor: "#ffffff",
    padding: "8px",
    minHeight: "35px",
  },
  markFilledButton: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
    backgroundColor: "#ffffff",
    padding: "8px",
    minHeight: "41px",
    marginRight: "15px",
  },
  talReqButton: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
    backgroundColor: "#ffffff",
    padding: "8px",
    minHeight: "41px",
  },

  grid1: {
    padding: "15px",
  },
  grid3: {
    padding: "15px",
  },
  buttonPin: {
    background: "#8f0aa1 !important",
    "&::after": {
      top: "97%",
      left: 20,
      border: "solid transparent",
      content: "''",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderTopColor: "#8f0aa1",
      borderWidth: "8px",
      marginLeft: "-8px",
      zIndex: 5,
    },
  },
  weeklySchedule: {
    fontWeight: "bold",
    fontSize: "21px",
    lineHeight: "25px",
  },
  inputContainer: {
    marginBottom: "15px !important",
  },
  containerInformationActions: {
    backgroundColor: "#ececec",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 2,
    paddingRight: 2,
    display: "flex",
    position: "absolute",
    width: "100%",
    bottom: 0,
    "& button": {
      padding: "7px 5px 7px 5px",
    },
  },
  iconDialogClose: {
    fontSize: "16px",
  },
  schTotal: {
    textAlign: "right",
    marginTop: 15,
  },
  updateSch: {
    marginTop: "10px",
  },
  delDialogBtnCenter: {
    justifyContent: "center",
    padding: 25,
  },
  delHeader: {
    display: "inline-block",
    position: "relative",
    top: -5,
  },

  newIndicator: {
    left: 0,
    color: "#4f98bc",
    width: 6,
    height: 6,
    marginTop: -2.5,
    borderRadius: "50%",
    backgroundColor: "#4f98bc",
    position: "relative",
    top: -1,
  },
  tabUpdated: {
    marginLeft: 5,
    fontSize: 16,
    color: "#4f98bc",
  },
  trType: {
    position: "relative",
    top: 7,
  },
  containerComments: {
    marginTop: 20,
    position: "relative",
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
  },
  inputEditComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
    width: "98%",
  },
  buttonOutlinedComment: {
    marginRight: theme.spacing(1),
  },
  overflowScroll: {
    overflow: "scroll",
    height: "100%",
  },
  posRate: {
    paddingRight: 3,
  },
  maxWidthDialog: {
    maxWidth: "660px",
  },
  formSelector: {
    minWidth: 350,
    display: "grid",
  },
  newFormAdj: {
    marginTop: -15,
    padding: "50px 30px 50px 30px",
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 38,
    height: 38,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  animationText: {
    color: "#4f98bc",
  },
  surveyContainerDialog: {
    padding: "0 40px 0 40px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  createNewSurvey: {
    textAlign: "center",
    fontWeight: "bold",
    fontWeight: "bold",
    fontSize: "16px",
  },
  permissionIcon: {
    position: "relative",
    top: "3px",
    marginRight: "10px",
  },
  permissionIconSurvey: {
    position: "relative",
    top: "8px",
    marginRight: "10px",
  },
  surveyButton: {
    color: "#3996C0",
    border: "1px solid #3996C0",
    fontWeight: "bold",
    paddingTop: "1px",
    paddingBottom: "1px",
    textTransform: "capitalize",
    minWidth: "100px",
    marginTop: 10,
    backgroundColor: "#ffffff",
    padding: "8px",
    minHeight: "35px",
    display: "block",
    margin: "auto",
  },
}));

export default useStyles;
